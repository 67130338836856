import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";

// components
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Account from "./pages/Account";
import SingleMovie from "./pages/SingleMovie";
import Movie from "./components/AmyMovie/Movie/Movie";
import ListViewPage from "./pages/ListViewPage";
import MyLists from "./pages/MyLists";
import ListCreate from "./pages/ListCreate";
import UserViewPage from "./pages/UserViewPage";
import Person from "./pages/Person.js";
import "./styles/global.css";

// import the AuthContextProvider
import { AuthContextProvider } from "./context/AuthContext";
import WatchedMovies from "./pages/WatchedMovies";
import Users from "./pages/Users";
import WatchList from "./pages/WatchList";

function App() {
  return (
    <div className="container">
      <AuthContextProvider>
        <AuthContent />
      </AuthContextProvider>
    </div>
  );
}

function ProtectedRoute({ children }) {
  const { user } = useAuthContext();

  return user ? children : <Navigate to="/login" />;
}

function AuthContent() {
  const { user, authIsReady } = useAuthContext();

  return (
    authIsReady && (
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/signup"
            element={user ? <Navigate to="/" /> : <Signup />}
          />
          <Route
            path="/login"
            element={user ? <Navigate to="/" /> : <Login />}
          />
          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          />
          <Route path="/movies/:id" element={<SingleMovie />} />
          <Route path="/lists/:id" element={<ListViewPage />} />
          <Route path="/myLists" element={<MyLists />} />
          <Route path="/listCreate" element={<ListCreate />} />
          <Route path="/users/:id" element={<UserViewPage />} />
          <Route path="/person/:id" element={<Person />} />
          <Route path="/watchedMovies" element={<WatchedMovies />} />
          <Route path="/watchList" element={<WatchList />} />
          <Route path="/users" element={<Users />} />
        </Routes>
      </BrowserRouter>
    )
  );
}

export default App;
