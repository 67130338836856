import React from "react";
import { Link } from "react-router-dom";
import styles from "./SearchResults.module.css";

const SearchResults = ({ movies = [], onAddMovie }) => {
  return (
    <div className={styles.searchResults}>
      {movies.map((movie) => (
        <div key={movie.id} className={styles.searchResultItem}>
          <Link to={`/movies/${movie.id}`}>
            <img
              src={`https://image.tmdb.org/t/p/w185${movie.poster_path}`}
              alt="{movie.title}"
            />
            <div className={styles.titleAndDate}>
              <div className={styles.title}>{movie.title}</div>
              <div className={styles.releaseDate}>
                {movie.release_date.substring(0, 4)}
              </div>
            </div>
          </Link>
          <button
            onClick={(e) => {
              e.preventDefault();
              onAddMovie(movie);
            }}
          >
            +
          </button>{" "}
        </div>
      ))}
    </div>
  );
};

export default SearchResults;
