import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useLogout } from "../../hooks/useLogout";
import { useAuthContext } from "../../hooks/useAuthContext";
import SearchResults from "./../SearchResults/SearchResults";
import styles from "./Navbar.module.css";
import { AiOutlineSearch } from "react-icons/ai";
import { RiMenu4Line } from "react-icons/ri";
import Logo from "../../assets/logo1.png";
import LogoYazili from "../../assets/logo.jpg";
import Icon from "../../assets/logo.jpg";
import { debounce } from "lodash";

export default function Navbar() {
  const { logout, isPending } = useLogout();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [searchedMovies, setSearchedMovies] = useState([]);
  const [searchFocus, setSearchFocus] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [mobileMenuItemsHidden, setMobileMenuItemsHidden] = useState(true);
  const [accountSubMenuOpen, setAccountSubMenuOpen] = useState(false);
  const [logoSource, setLogoSource] = useState(LogoYazili);
  const [errorMessage, setErrorMessage] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMobileMenuOpen(false);
        setSearchBarOpen(true);
        setLogoSource(Icon);
      } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
        // Tablet boyutları için ayarlar
        setMobileMenuOpen(false);
        setSearchBarOpen(false);
        setLogoSource(Logo);
      } else {
        // Masaüstü boyutları için ayarlar
        setMobileMenuOpen(false);
        setSearchBarOpen(false);
        setLogoSource(Logo);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setMobileMenuOpen(false);
      setSearchBarOpen(true);
    } else {
      setMobileMenuOpen(false);
      setSearchBarOpen(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setSearchBarOpen(true);
    }
  }, []);

  const handleMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    setMobileMenuItemsHidden(!mobileMenuItemsHidden);
  };

  const handleSearchToggle = () => {
    if (searchBarOpen) {
      searchMovie();
    }
    setSearchBarOpen(!searchBarOpen);
  };

  const handleAccountSubMenuOpen = () => {
    setAccountSubMenuOpen(true);
  };

  const handleAccountSubMenuClose = () => {
    setAccountSubMenuOpen(false);
  };

  const debouncedSearch = debounce((query) => {
    searchMovie(query);
  }, 500);

  const searchMovie = async (query) => {
    try {
      const url = `https://api.themoviedb.org/3/search/movie?api_key=bcc4ff10c2939665232d75d8bf0ec093&query=${query}`;
      const res = await fetch(url);
      const data = await res.json();
      setSearchedMovies(data.results);
    } catch (err) {
      console.error(err);
      setErrorMessage(
        "Arama yaparken bir hata oluştur. Lütfen tekrar deneyiniz."
      );
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.log("Failed to log out");
    }
  };

  const changeHandler = (e) => {
    setQuery(e.target.value);
    if (e.target.value) {
      debouncedSearch(e.target.value);
    } else {
      setSearchedMovies([]);
    }
  };

  const handleFocus = () => {
    setSearchBarOpen(true);
    setSearchFocus(true);
  };

  const handleBlur = () => {
    setSearchBarOpen(false);
    setTimeout(() => setSearchFocus(false), 200);
  };

  const handleMenuItemClick = () => {
    if (window.innerWidth <= 768) {
      setMobileMenuOpen(false);
    }
  };

  const location = useLocation();

  useEffect(() => {
    setQuery("");
  }, [location]);

  return (
    <div className={styles.navbar}>
      <button className={styles.mobileMenuToggle} onClick={handleMenuToggle}>
        <RiMenu4Line size={24} />
      </button>
      <Link to="/" className={styles.logo} onClick={handleMenuItemClick}>
        <img src={Logo} alt="Logo" className={styles.logoImage} />
        <img src={LogoYazili} alt="Logo" className={styles.logoImageYazili} />
      </Link>
      <div className={styles.searchContainer}>
        <button
          className={styles.searchToggle}
          onClick={() => setSearchBarOpen(!searchBarOpen)}
        >
          <AiOutlineSearch size={24} />
        </button>
        <li className={styles.searchLi}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              searchMovie(query);
            }}
            autoComplete="off"
            className={`${styles.searchForm} ${
              searchBarOpen ? styles.open : ""
            }`}
          >
            <input
              type="search"
              name="query"
              value={query}
              onChange={changeHandler}
              onFocus={handleFocus}
              onBlur={handleBlur}
              className={styles.searchInput}
            />
          </form>
          {searchFocus && query && <SearchResults movies={searchedMovies} />}
        </li>
      </div>
      <ul
        className={`${styles.menu} ${
          mobileMenuOpen ? styles.mobileMenuOpen : ""
        }`}
      >
        {!user && (
          <>
            <li>
              <Link to="/login">Giriş</Link>
            </li>
            <li>
              <Link to="/signup">Kayıt</Link>
            </li>
          </>
        )}
        {user && (
          <>
            <li>
              <Link to="/watchList" onClick={handleMenuItemClick}>
                İzleme Listem
              </Link>
              <Link to="/watchedMovies" onClick={handleMenuItemClick}>
                İzlediklerim
              </Link>
              <Link to="/users" onClick={handleMenuItemClick}>
                Sosyal
              </Link>
              <Link to="/myLists" onClick={handleMenuItemClick}>
                Listeler
              </Link>
            </li>
            <li
              className={styles.navLink}
              onMouseEnter={handleAccountSubMenuOpen}
              onMouseLeave={handleAccountSubMenuClose}
            >
              <Link to="/account" onClick={handleMenuItemClick}>
                <span>Hesap</span>
              </Link>
              <ul
                className={`${styles.accountSubMenu} ${
                  accountSubMenuOpen ? styles.open : ""
                }`}
                onMouseEnter={handleAccountSubMenuOpen}
                onMouseLeave={handleAccountSubMenuClose}
              >
                <li>
                  <Link to="/account" onClick={handleMenuItemClick}>
                    Profil
                  </Link>
                </li>
                <li>
                  {!isPending && (
                    <button className={styles.btnCikis} onClick={handleLogout}>
                      Çıkış
                    </button>
                  )}
                  {isPending && (
                    <button className={styles.btnCikis} disabled>
                      Çıkış yapılıyor...
                    </button>
                  )}
                </li>
              </ul>
            </li>
          </>
        )}
      </ul>
      <div>
        {errorMessage && <div className={styles.error}>{errorMessage}</div>}
      </div>
    </div>
  );
}
