import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { API_KEY } from "../../config";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCheck,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useUserData } from "../../hooks/useUserData";
import { db } from "../../firebase/config";
import {
  collection,
  doc,
  updateDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import UserAvatarCard from "../UserAvatarCard/UserAvatarCard";
import DefaultAvatar from "../../assets/unisex_avatar.jpg";

import styles from "./HomeListsViewCard.module.css";

function HomeListsViewCard({ list }) {
  const { ad, filmler, takipEdenler } = list;
  const [movies, setMovies] = useState([]);
  const BASE_IMG_URL = "https://image.tmdb.org/t/p/w500";

  const { user } = useAuthContext();
  const allUserData = useUserData(user?.uid); // always call the hook
  const { userData, loading } = user
    ? allUserData
    : { userData: null, loading: false };

  const [addedToWatchedMovies, setAddedToWatchedMovies] = useState([]);

  const getMovieById = async (movie_id) => {
    const response = await axios.get(
      `https://api.themoviedb.org/3/movie/${movie_id}?api_key=${API_KEY}&language=tr`
    );
    return response.data;
  };

  const getMovies = async () => {
    if (filmler.length > 0) {
      const movies = await Promise.all(
        filmler.slice(0, 5).map((film) => {
          const movie_id = typeof film === "object" ? film.id : film;
          return getMovieById(movie_id);
        })
      );
      return movies;
    } else {
      const response = await axios.get(
        `https://api.themoviedb.org/3/movie/${list.tag}?api_key=${API_KEY}&language=tr`
      );
      // console.log("response:", response);
      return response.data.results;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const movies = await getMovies();
      setMovies(movies);

      // Check if each movie is in the watchedMovies list
      if (userData) {
        const watchedStatus = movies.map(
          (movie) => userData?.watchedMovies?.includes(movie.id) ?? false
        );
        setAddedToWatchedMovies(watchedStatus);
      }
    };
    fetchData();
  }, [userData]);

  const handleAddToWatched = async (movieId, e, index) => {
    e.preventDefault();
    if (!user) {
      // Kullanıcı yoksa bu fonksiyonu çalıştırmayın
      console.log("Kullanıcı giriş yapmadı.");
      return;
    }
    const userRef = doc(db, "users", user.uid);

    await updateDoc(userRef, {
      watchedMovies: arrayUnion(movieId),
    });

    const updatedStatus = [...addedToWatchedMovies];
    updatedStatus[index] = true;
    setAddedToWatchedMovies(updatedStatus);
  };

  const [listCreator, setListCreator] = useState(null);

  useEffect(() => {
    const fetchListCreator = async () => {
      const userRef = doc(db, "users", list.olusturan);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        setListCreator(userSnap.data());
      } else {
        console.log("No such user!");
      }
    };

    fetchListCreator();
  }, [list]);

  return (
    <div className={styles.listCardContainer}>
      <div className={styles.listDetailContainer}>
        <span className={styles.listDescription}>{list.aciklama}</span>
        <span className={styles.listOwner}>
          oluşturan:
          {listCreator && (
            <Link
              className={styles.listCreatorUser}
              to={`/users/${list.olusturan}`}
              state={{ user: listCreator }}
            >
              <span>{listCreator.displayName}</span>
              <img
                src={listCreator.avatar || DefaultAvatar}
                className={styles.userAvatar}
                alt="user-avatar"
              />
            </Link>
          )}
        </span>
        <span className={styles.listCardTitleWrapper}>
          <Link
            className={styles.listCardTitle}
            to={`/lists/${list.id}`}
            state={{ list: list }}
          >
            listeye git
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </span>
      </div>

      <div className={styles.listCardImageGrid}>
        {movies.slice(0, 10).map((movie, index) => (
          <div
            className={`${styles.carouselItem} ${
              userData && addedToWatchedMovies[index] ? styles.watchedItem : ""
            }`}
            key={movie.id}
          >
            <Link to={`/movies/${movie.id}`} state={{ movie: movie }}>
              <img
                className={styles.carouselThumb}
                src={`${BASE_IMG_URL}${movie.poster_path}`}
                alt={movie.title}
              />
            </Link>
            <div
              className={`${styles.carouselContent} ${
                userData && addedToWatchedMovies[index]
                  ? styles.watchedContent
                  : ""
              }`}
            >
              <Link to={`/movies/${movie.id}`} state={{ movie: movie }}>
                <h2 className={styles.carouselTitle}>{movie.title}</h2>
              </Link>
              <span className={styles.carouselRelease}>
                Vizyon tarihi: {movie.release_date}
              </span>
              <div className={styles.carouselButton}>
                <button className={`${styles.button} ${styles.leftButton}`}>
                  <FontAwesomeIcon icon={faPlus} />
                  <span>İzleme listeme ekle</span>
                </button>
                {user && (
                  <button
                    className={`${styles.button} ${styles.rightButton} ${
                      addedToWatchedMovies[index] ? styles.watched : ""
                    }`}
                    onClick={(e) => handleAddToWatched(movie.id, e, index)}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                    <span>İzlediklerime ekle</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomeListsViewCard;
