import { useState, useEffect } from "react";
import { API_KEY } from "../../config";
import axios from "axios";
import styles from "./HomeListsViewCardForEveryone.module.css";

function HomeListsViewCardForEveryone({ list }) {
  const [filmler, setFilmler] = useState([]);
  const BASE_IMG_URL = "https://image.tmdb.org/t/p/w500";

  const getFilmler = async () => {
    if (list.filmler.length > 0) {
      const filmler = await Promise.all(
        list.filmler.slice(0, 5).map((film) => {
          const film_id = typeof film === "object" ? film.id : film;
          return getMovieById(film_id);
        })
      );
      return filmler;
    } else {
      const response = await axios.get(
        `https://api.themoviedb.org/3/movie/${list.tag}?api_key=${API_KEY}&language=tr`
      );
      // console.log("response:", response);
      return response.data.results;
    }
  };

  const getMovieById = async (film_id) => {
    const response = await axios.get(
      `https://api.themoviedb.org/3/movie/${film_id}?api_key=${API_KEY}&language=tr`
    );
    return response.data;
  };

  useEffect(() => {
    const fetchData = async () => {
      const filmler = await getFilmler();
      setFilmler(filmler);
    };
    fetchData();
  }, []);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.moviesGrid}>
        {filmler.slice(0, 4).map((film, index) => (
          <div className={styles.movieItem} key={index}>
            <img
              className={styles.moviePoster}
              src={`${BASE_IMG_URL}${film.poster_path}`}
              alt={`${film.title} poster`}
            />
            <div className={styles.movieTitleOverlay}>
              <span>{film.title}</span> {/* Metni span içine alın */}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.listName}>{list.ad}</div>
      <div className={styles.listDescription}>{list.aciklama}</div>
      <div className={styles.listStats}>
        {list.filmler.length} film / {list.takipEdenler.length} takipçi
      </div>
    </div>
  );
}

export default HomeListsViewCardForEveryone;
