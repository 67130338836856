import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadString, getDownloadURL } from "firebase/storage"; // Firebase Storage importları
import { auth, db, storage } from "../firebase/config";

export const useSignup = () => {
  const [error, setError] = useState(null);
  const { dispatch } = useAuthContext();
  const [isPending, setIsPending] = useState(false);

  const signup = async (email, password, displayName, avatar) => {
    setError(null);
    setIsPending(true);

    try {
      // Kullanıcıyı oluşturun
      const res = await createUserWithEmailAndPassword(auth, email, password);

      // Avatarı Firebase Storage'a yükleyin
      const storageRef = ref(storage, `avatars/${res.user.uid}.svg`);
      await uploadString(storageRef, avatar, "data_url");
      const avatarUrl = await getDownloadURL(storageRef);

      // Firestore'da kullanıcı verilerini kaydedin
      const userDocRef = doc(db, "users", res.user.uid);
      const userData = {
        displayName: displayName,
        avatar: avatarUrl, // Store the avatar URL from Firebase Storage
        followedLists: [],
        watchedMovies: [],
        followedUsers: [],
      };

      await setDoc(userDocRef, userData);

      // Kullanıcı profilini güncelleyin
      await updateProfile(res.user, {
        displayName: displayName,
        photoURL: avatarUrl, // Use the short URL from Firebase Storage
      });

      // Kullanıcıyı oturum açmış olarak ayarlayın
      dispatch({ type: "LOGIN", payload: res.user });
    } catch (err) {
      setError(err.message);
    } finally {
      setIsPending(false);
    }
  };

  return { error, isPending, signup };
};
