import ListCreateForm from "../components/ListCreateForm/ListCreateForm";
import styles from "../styles/MyLists.module.css";
import { useAuthContext } from "../hooks/useAuthContext";
import { useUserData } from "../hooks/useUserData";
import { useCollection } from "../hooks/useCollection";
import MyListsListViewItem from "../components/MyListsListViewItem/MyListsListViewItem";

function MyLists() {
  const { user } = useAuthContext();
  const { userData, loading } = useUserData(user.uid);

  const { documents: lists } = useCollection("listeler");

  const takipEdilenListeleriGetir = () => {
    if (loading || !userData || !userData.followedLists || !lists) {
      return null;
    }

    return userData.followedLists.map((listId) => {
      const matchedList = lists.find((list) => {
        return list.id.trim() === listId.trim();
      });

      if (matchedList) {
        return (
          <MyListsListViewItem
            key={listId}
            list={matchedList}
            tag={lists.tag}
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <div className={styles.myListsContainer}>
      <div className={styles.followedListsContainer}>
        <h1 className={styles.myListsTitle}>Takip edilen listeler</h1>
        <div className={styles.listItemContainer}>
          {takipEdilenListeleriGetir()}
        </div>
      </div>
      {/* <div className={styles.listAddForm}>
        <ListCreateForm />
      </div> */}
    </div>
  );
}

export default MyLists;
