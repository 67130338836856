import React from "react";
import styles from "./AccountListView.module.css";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { API_KEY } from "../../config";
import axios from "axios";
import { useCollection } from "../../hooks/useCollection";
import { Link } from "react-router-dom";

function AccountListView({ list, tag }) {
  const { ad, filmler, takipEdenler } = list;
  const { user } = useAuthContext();

  const [moviePosters, setMoviePosters] = useState([]);
  const MAX_MOVIES_DISPLAY = 7;

  const BASE_IMG_URL = "https://image.tmdb.org/t/p/w500";

  const getMovieById = async (movie_id) => {
    const response = await axios.get(
      `https://api.themoviedb.org/3/movie/${movie_id}?api_key=${API_KEY}&language=tr`
    );
    return response.data;
  };

  const getMovies = async () => {
    if (filmler.length > 0) {
      const movies = await Promise.all(
        filmler.map((movie_id) => getMovieById(movie_id))
      );
      return movies;
    } else {
      const response = await axios.get(
        `https://api.themoviedb.org/3/discover/movie?api_key=${API_KEY}&language=tr&sort_by=popularity.desc&with_genres=${tag}`
      );
      return response.data.results;
    }
  };

  useEffect(() => {
    const fetchMoviePosters = async () => {
      const movies = await getMovies();
      const moviePosterPromises = movies
        .slice(0, MAX_MOVIES_DISPLAY)
        .map((movie) => {
          return (
            movie && (
              <Link to={`/movies/${movie.id}`} state={{ movie: movie }}>
                <img
                  className={styles.listItemPoster}
                  src={`${BASE_IMG_URL}${movie.poster_path}`}
                  alt={movie.title}
                  title={movie.title} // Bu satırı ekledik.
                />
              </Link>
            )
          );
        });

      const fetchedMoviePosters = await Promise.all(moviePosterPromises);
      setMoviePosters(fetchedMoviePosters);
    };

    if (filmler || tag) {
      fetchMoviePosters();
    }
  }, [filmler, tag]);

  const listedekiFilmler = () => {
    if (!moviePosters) {
      return null;
    }

    return moviePosters;
  };

  return (
    <div className={styles.accountListView}>
      <h2 className={styles.listTitle}>{ad}</h2>
      <div className={styles.listDetails}>
        <ul className={styles.listFilmler}>{listedekiFilmler()}</ul>
        <span className={styles.listTakipEdenler}>
          Takipçi: {takipEdenler.length}
        </span>
      </div>
    </div>
  );
}

export default AccountListView;
