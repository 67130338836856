import React from "react";
import "./WelcomeComponent.css";
import register from "./../../../assets/register2.png";
import watchMovies from "./../../../assets/watch_movies.png";
import bragAboutIt from "./../../../assets/brag_about_it.png";

const WelcomeComponent = () => {
  return (
    <div className="welcome-container">
      <div className="features">
        <div className="feature-item">
          <img
            src={register}
            alt="Profilini Oluştur"
            className="feature-image"
          />
          <div className="feature-text">Profilini Oluştur</div>
        </div>
        <div className="feature-item">
          <img
            src={watchMovies}
            alt="Bir sürü film izle"
            className="feature-image"
          />
          <div className="feature-text">Bir sürü film izle</div>
        </div>
        <div className="feature-item">
          <img
            src={bragAboutIt}
            alt="Arkadaşlarınla üzerinde konuş"
            className="feature-image"
          />
          <div className="feature-text">Filmlerin üzerine sohbet et</div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeComponent;
