import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSignup } from "../hooks/useSignup";
import { createAvatar } from "@dicebear/avatars";
import * as style from "@dicebear/croodles";
import * as lorelei from "@dicebear/lorelei";

// styles
import styles from "../styles/Signup.module.css";

export default function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [avatar, setAvatar] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const { error, signup } = useSignup();
  const navigate = useNavigate();

  const avatarOptions = Array(50)
    .fill(0)
    .map(
      (_, i) =>
        "data:image/svg+xml," +
        encodeURIComponent(createAvatar(style, { seed: i.toString() }))
    );

  const handleSubmit = async (e) => {
    e.preventDefault();
    await signup(email, password, displayName, avatar);
    navigate("/login");
  };

  const handleAvatarChange = (selectedAvatar) => {
    setAvatar(selectedAvatar);
    setShowAlert(true);
  };

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  }, [showAlert]);

  return (
    <form className={styles.authForm} onSubmit={handleSubmit}>
      <h2>Kayıt</h2>
      <label className={styles.label}>
        <span>Email:</span>
        <input
          required
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          className={styles.input}
        />
      </label>
      <label className={styles.label}>
        <span>Şifre:</span>
        <input
          required
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          className={styles.input}
        />
      </label>
      <label className={styles.label}>
        <span>Kullanıcı adı:</span>
        <input
          required
          type="text"
          onChange={(e) => setDisplayName(e.target.value)}
          value={displayName}
          className={styles.input}
        />
      </label>
      <p className={styles.alert}>{showAlert ? "Avatar seçildi" : ""}</p>
      <div className={styles.avatarContainer}>
        {avatarOptions.map((avatarSrc, index) => (
          <img
            key={index}
            src={avatarSrc}
            alt={`Avatar ${index}`}
            onClick={() => handleAvatarChange(avatarSrc)}
            className={styles.avatar}
          />
        ))}
      </div>
      <button className={styles.btn}>Kaydol</button>
      {/* {error && <p className={styles.error}>{error}</p>} */}
      <p className={styles.loginLink}>
        Zaten üye misiniz? <Link to="/login">Buradan giriş yapabilirsiniz</Link>
      </p>
    </form>
  );
}
