import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HomeListsViewCardForEveryone from "../HomeListsViewCardForEveryone/HomeListsViewCardForEveryone";
import styles from "./HomeListsViewForEveryone.module.css";

function HomeListsViewForEveryone({ lists }) {
  const [activeTab, setActiveTab] = useState("followers"); // 'followers' veya 'movies' olabilir

  // lists'in undefined veya null olup olmadığını kontrol et
  if (!lists) {
    return <div>Loading...</div>; // Veya istediğiniz başka bir yükleme göstergesi
  }

  // Takipçi sayısına göre sıralama
  const sortedByFollowers = [...lists].sort(
    (a, b) => b.takipEdenler.length - a.takipEdenler.length
  );

  // Film sayısına göre sıralama
  const sortedByMovies = [...lists].sort(
    (a, b) => b.filmler.length - a.filmler.length
  );

  // Aktif sekme için listeleri getir
  const getListsForActiveTab = () => {
    return activeTab === "followers" ? sortedByFollowers : sortedByMovies;
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <div
          className={`${styles.tab} ${
            activeTab === "followers" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("followers")}
        >
          En çok izlenenler
        </div>
        <div
          className={`${styles.tab} ${
            activeTab === "movies" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("movies")}
        >
          Popüler Listeler
        </div>
      </div>
      <div className={styles.cardsContainer}>
        {getListsForActiveTab().map((list) => (
          <Link
            key={list.id}
            className={styles.listCardTitle}
            to={`/lists/${list.id}`}
            state={{ list: list }}
          >
            <HomeListsViewCardForEveryone list={list} />
          </Link>
        ))}
      </div>
    </div>
  );
}

export default HomeListsViewForEveryone;
