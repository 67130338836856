import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Modal.module.css";

const Modal = ({ onClose }) => {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/login");
  };

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <button onClick={onClose} className={styles.closeButton}>
          X
        </button>
        <p>Bu işlemi yapmak için giriş yapmalısınız.</p>
        <div className={styles.buttonContainer}>
          <button onClick={navigateToLogin} className={styles.loginButton}>
            Giriş Yap
          </button>
          <button onClick={onClose} className={styles.closeModalButton}>
            Kapat
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
