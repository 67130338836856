import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./ListViewItem.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faStar, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useUserData } from "../../hooks/useUserData";
import Modal from "./Modal";

// firebase imports
import { db } from "../../firebase/config";
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

function ListViewItem({ movie, onMovieWatchedStatus }) {
  const BASE_IMG_URL = "https://image.tmdb.org/t/p/w500";

  const { user } = useAuthContext();
  // userData ve loading'i her render'da tanımla, ama koşullu olarak kullan
  const userDataResponse = useUserData(user ? user.uid : null);
  const userData = user ? userDataResponse.userData : null;
  const loading = user ? userDataResponse.loading : false;
  const [showModal, setShowModal] = useState(false);

  const [addedToWatchedMovies, setAddedToWatchedMovies] = useState(false);
  const [addedToWatchList, setAddedToWatchList] = useState(false);
  const [watchListHoverText, setWatchListHoverText] = useState(
    "İzleme listesine ekle"
  );
  const [watchedMoviesHoverText, setWatchedMoviesHoverText] =
    useState("İzlediklerime ekle");

  useEffect(() => {
    if (userData) {
      const isMovieInWatchedMovies = userData.watchedMovies?.includes(movie.id);
      setAddedToWatchedMovies(isMovieInWatchedMovies);
      setWatchedMoviesHoverText(
        isMovieInWatchedMovies ? "İzlediklerimden çıkart" : "İzlediklerime ekle"
      );
      const isMovieInWatchList = userData.watchList?.includes(movie.id);
      setAddedToWatchList(isMovieInWatchList);
      setWatchListHoverText(
        isMovieInWatchList
          ? "İzleme listesinden çıkart"
          : "İzleme listesine ekle"
      );
      onMovieWatchedStatus &&
        onMovieWatchedStatus(movie.id, isMovieInWatchedMovies);
    }
  }, [userData, movie]);

  const handleAddToWatched = async () => {
    const userRef = doc(db, "users", user.uid);
    if (addedToWatchedMovies) {
      await updateDoc(userRef, {
        watchedMovies: arrayRemove(movie.id),
        watchList: arrayRemove(movie.id),
      });
      setAddedToWatchedMovies(false);
      setWatchedMoviesHoverText("İzlediklerime ekle");

      // event kayıt etme
      await addDoc(collection(db, "events"), {
        userId: user.uid,
        movieId: movie.id,
        action: "removedFromWatched",
        timestamp: new Date(),
      });
    } else {
      await updateDoc(userRef, {
        watchedMovies: arrayUnion(movie.id),
      });
      setAddedToWatchedMovies(true);
      setWatchedMoviesHoverText("İzlediklerimden çıkart");
      if (addedToWatchList) {
        setAddedToWatchList(false);
        setWatchListHoverText("İzleme listesine ekle");

        // event kayıt etme
        await addDoc(collection(db, "events"), {
          userId: user.uid,
          movieId: movie.id,
          action: "movedFromWatchListToWatched",
          timestamp: new Date(),
        });
      } else {
        // event kayıt etme
        await addDoc(collection(db, "events"), {
          userId: user.uid,
          movieId: movie.id,
          action: "addedToWatched",
          timestamp: new Date(),
        });
      }
    }
  };

  const handleAddToWatchList = async () => {
    const userRef = doc(db, "users", user.uid);
    if (addedToWatchList) {
      await updateDoc(userRef, {
        watchList: arrayRemove(movie.id),
      });
      setAddedToWatchList(false);
      setWatchListHoverText("İzleme listesine ekle");

      // event kayıt etme
      await addDoc(collection(db, "events"), {
        userId: user.uid,
        movieId: movie.id,
        action: "removedFromWatchList",
        timestamp: new Date(),
      });
    } else {
      await updateDoc(userRef, {
        watchList: arrayUnion(movie.id),
        watchedMovies: arrayRemove(movie.id),
      });
      setAddedToWatchList(true);
      setWatchListHoverText("İzleme listesinden çıkart");
      if (addedToWatchedMovies) {
        setAddedToWatchedMovies(false);
        setWatchedMoviesHoverText("İzlediklerime ekle");

        // event kayıt etme
        await addDoc(collection(db, "events"), {
          userId: user.uid,
          movieId: movie.id,
          action: "movedFromWatchedToWatchList",
          timestamp: new Date(),
        });
      } else {
        // event kayıt etme
        await addDoc(collection(db, "events"), {
          userId: user.uid,
          movieId: movie.id,
          action: "addedToWatchList",
          timestamp: new Date(),
        });
      }
    }
  };

  const handleUserAction = (action, e) => {
    if (!user) {
      setShowModal(true);
      return;
    }

    if (e) e.preventDefault();

    switch (action) {
      case "addToWatched":
        handleAddToWatched();
        break;
      case "addToWatchList":
        handleAddToWatchList();
        break;
      // Diğer durumlar...
    }
  };

  const closeModal = () => {
    setShowModal(false); // Modalı kapat
  };

  return (
    <div className={styles.listViewItem}>
      <Link to={`/movies/${movie.id}`} state={{ movie: movie }}>
        <div className={styles.listViewItemContent}>
          <img
            className={styles.listItemPoster}
            src={`${BASE_IMG_URL}${movie.poster_path}`}
            alt="movie-title"
          />
          <div className={styles.listViewItemDetails}>
            <h2 className={styles.listViewTitle}>{movie.title}</h2>
            <p className={styles.originalTitle}>{movie.original_title}</p>
            <div className={styles.voteAverage}>
              <p>Puan: {Number(movie.vote_average).toFixed(1)}</p>
            </div>
            <p className={styles.releaseDate}>
              Çıkış tarihi:{" "}
              {new Date(movie.release_date).toLocaleDateString("tr-TR")}
            </p>
          </div>
        </div>
      </Link>

      <div className={styles.buttonGroup}>
        <button
          className={`${styles.button} ${addedToWatchList ? styles.added : ""}`}
          onClick={(e) => {
            e.preventDefault();
            handleUserAction("addToWatchList", e);
          }}
          onMouseEnter={() =>
            setWatchListHoverText(
              addedToWatchList
                ? "İzleme listesinden çıkart"
                : "İzleme listesine ekle"
            )
          }
          onMouseLeave={() =>
            setWatchListHoverText(
              addedToWatchList
                ? "İzleme listesinden çıkart"
                : "İzleme listesine ekle"
            )
          }
        >
          <FontAwesomeIcon icon={faPlus} />
          <span>{watchListHoverText}</span>
        </button>

        <button
          className={styles.button}
          onClick={() => handleUserAction("rateMovie")} // Puanla butonu için modal gösterimi
        >
          <FontAwesomeIcon icon={faStar} />
          <span>Puanla</span>
        </button>

        <button
          className={`${styles.button} ${
            addedToWatchedMovies ? styles.watched : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            handleUserAction("addToWatched", e);
          }}
          onMouseEnter={() =>
            setWatchedMoviesHoverText(
              addedToWatchedMovies
                ? "İzlediklerimden çıkart"
                : "İzlediklerime ekle"
            )
          }
          onMouseLeave={() =>
            setWatchedMoviesHoverText(
              addedToWatchedMovies
                ? "İzlediklerimden çıkart"
                : "İzlediklerime ekle"
            )
          }
        >
          <FontAwesomeIcon icon={faCheck} />
          <span>{watchedMoviesHoverText}</span>
        </button>
      </div>

      {showModal && (
        <Modal onClose={closeModal}>
          <p>Bu işlemi yapmak için giriş yapmalısınız.</p>
          <button onClick={closeModal}>Kapat</button>
        </Modal>
      )}
    </div>
  );
}

export default ListViewItem;
