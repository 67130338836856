import { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { API_KEY } from "../config";
import { useParams } from "react-router-dom";

import { useCollection } from "../hooks/useCollection";
import AccountListView from "../components/AccountListView/AccountListView";
import AccountMovieListViewItem from "../components/AccountMovieListViewItem/AccountMovieListViewItem";
import AccountRecentActivities from "../components/AccountRecentActivities/AccountRecentActivities";

import Avatar from "../assets/unisex_avatar.jpg";

import styles from "../styles/UserViewPage.module.css";

import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { db } from "../firebase/config";

import { useAuthContext } from "../hooks/useAuthContext";

import { useUserData } from "../hooks/useUserData";

export default function UserViewPage() {
  const { id: viewedUserId } = useParams();

  // giriş yapmış olan kullanıcıyı getirme
  const { user: loggedInUser } = useAuthContext();
  const { userData: loggedInUserData, loading: loggedInUserLoading } =
    useUserData(loggedInUser?.uid);

  const [viewedUser, setViewedUser] = useState(null);
  const [movies, setMovies] = useState({});
  const [tab, setTab] = useState("watched");

  const { documents: lists } = useCollection("listeler");
  const { documents: users } = useCollection("users");

  const [activeList, setActiveList] = useState("watchList");

  const watchListLength =
    viewedUser && viewedUser.watchList ? viewedUser.watchList.length : 0;
  const watchedMoviesLength =
    viewedUser && viewedUser.watchedMovies
      ? viewedUser.watchedMovies.length
      : 0;

  const listeyiGetir = (listName) => {
    const movieList = viewedUser[listName];
    if (!movieList) {
      return null;
    }
    return movieList.map((movie_id) => {
      const movie = movies[movie_id];
      return (
        movie && (
          <AccountMovieListViewItem
            className="accountMovieCard"
            key={movie_id}
            movie={movie}
          />
        )
      );
    });
  };

  const getMovieById = async (movie_id) => {
    const response = await axios.get(
      `https://api.themoviedb.org/3/movie/${movie_id}?api_key=${API_KEY}&language=tr`
    );
    return response.data;
  };

  useEffect(() => {
    const fetchUser = async () => {
      const matchedUser = users.find((user) => user.id === viewedUserId);
      if (matchedUser) {
        setViewedUser(matchedUser);
      } else {
        console.error(
          "User getirilirken bir hata oluştu: ",
          "Kullanıcı bulunamadı"
        );
      }
    };

    if (users) {
      fetchUser();
    }
  }, [viewedUserId, users]);

  useEffect(() => {
    if (tab === "watched") {
      fetchMovies();
    } else {
      fetchWatchListMovies();
    }
  }, [tab, viewedUser]);

  const fetchMovies = async () => {
    if (viewedUser && viewedUser.watchedMovies) {
      try {
        const moviePromises = viewedUser.watchedMovies.map(getMovieById);
        const movieResults = await Promise.all(moviePromises);
        const fetchedMovies = movieResults.reduce((acc, currMovie, index) => {
          acc[viewedUser.watchedMovies[index]] = currMovie;
          return acc;
        }, {});
        setMovies(fetchedMovies);
      } catch (error) {
        console.error("Movies getirilirken bir hata oluştu: ", error);
      }
    }
  };

  const fetchWatchListMovies = async () => {
    if (viewedUser && viewedUser.watchList) {
      try {
        const moviePromises = viewedUser.watchList.map(getMovieById);
        const movieResults = await Promise.all(moviePromises);
        const fetchedMovies = movieResults.reduce((acc, currMovie, index) => {
          acc[viewedUser.watchList[index]] = currMovie;
          return acc;
        }, {});
        setMovies(fetchedMovies);
      } catch (error) {
        console.error(
          "Watch list movies getirilirken bir hata oluştu: ",
          error
        );
      }
    }
  };

  const moviesList = useMemo(() => {
    if (
      !viewedUser ||
      !(viewedUser.watchedMovies || viewedUser.watchList) ||
      !movies
    ) {
      return null;
    }
    const currentMoviesList =
      tab === "watched" ? viewedUser.watchedMovies : viewedUser.watchList;

    return currentMoviesList.map((movie_id) => {
      const movie = movies[movie_id];
      return (
        movie && (
          <AccountMovieListViewItem
            className="accountMovieCard"
            key={movie_id}
            movie={movie}
          />
        )
      );
    });
  }, [viewedUser, movies, tab]);

  const followedLists = useMemo(() => {
    if (!viewedUser || !viewedUser.followedLists || !lists) {
      return null;
    }

    return viewedUser.followedLists.map((listId) => {
      const matchedList = lists.find(
        (list) => list.id.trim() === listId.trim()
      );

      if (matchedList) {
        return <AccountListView key={listId} list={matchedList} />;
      } else {
        return null;
      }
    });
  }, [viewedUser, lists]);

  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    if (loggedInUserData && loggedInUserData.followedUsers) {
      setIsFollowing(loggedInUserData.followedUsers.includes(viewedUserId));
    }
  }, [loggedInUserData, viewedUserId]);

  const handleFollowUser = async () => {
    const userRef = doc(db, "users", loggedInUser?.uid);

    if (isFollowing) {
      await updateDoc(userRef, {
        followedUsers: arrayRemove(viewedUserId),
      });
      setIsFollowing(false);
    } else {
      await updateDoc(userRef, {
        followedUsers: arrayUnion(viewedUserId),
      });
      setIsFollowing(true);
    }
  };

  return (
    <div className={styles.container}>
      {viewedUser ? (
        <>
          <div className={styles.userInfo}>
            <img
              src={viewedUser.avatar || Avatar}
              className={styles.userAvatar}
              alt="user-avatar-img"
            />
            <div>
              <h1 className={styles.userTitle}>{viewedUser.displayName}</h1>
              <button
                style={{ backgroundColor: isFollowing ? "green" : "gray" }}
                onClick={handleFollowUser}
              >
                {isFollowing ? "Takip ediliyor" : "Takip et"}
              </button>
              <p>{watchedMoviesLength} film izledi</p>
              <p>{watchListLength} film sırada</p>
            </div>
          </div>
          <div className={styles.listsFilmsContainer}>
            <AccountRecentActivities
              className="AccountRecentActivities"
              userId={viewedUserId}
            />

            <div className={styles.listsContainer}>
              <h2 className={styles.ContainerTitle}>Takip ettiği listeler</h2>
              {followedLists}
            </div>
            <div className={styles.filmsContainer}>
              <div className={styles.FilmsTitle}>
                <span
                  className={`${styles.titleOption} ${
                    activeList === "watchList" ? styles.selectedTitle : ""
                  }`}
                  onClick={() => setActiveList("watchList")}
                >
                  İzleme Listesi
                </span>
                <span
                  className={`${styles.titleOption} ${
                    activeList === "watchedMovies" ? styles.selectedTitle : ""
                  }`}
                  onClick={() => setActiveList("watchedMovies")}
                >
                  İzledikleri
                </span>
              </div>
              <div className={styles.moviesContainer}>
                {listeyiGetir(activeList)}
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
