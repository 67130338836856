import React, { useEffect, useState } from "react";
import { db } from "../../firebase/config";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import DefaultAvatar from "../../assets/unisex_avatar.jpg";
import styles from "./AccountRecentActivities.module.css";
import { API_KEY } from "../../config";

function UserActivities({ userId }) {
  const [activities, setActivities] = useState([]);
  const [movies, setMovies] = useState({});

  const POSTER_BASE_URL = "https://image.tmdb.org/t/p/w200";

  const getActionText = (action) => {
    switch (action) {
      case "addedToWatchList":
        return "İzleme listesine ekledi";
      case "addedToWatched":
        return "İzlediklerime ekledi";
      default:
        return "Bir işlem yaptı";
    }
  };

  const formatDateFromTimestamp = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString("tr-TR"); // 'tr-TR' formatında tarih döndürür
  };

  useEffect(() => {
    const q = query(
      collection(db, "events"),
      where("userId", "==", userId),
      orderBy("timestamp", "desc")
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      let activitiesData = snapshot.docs.map((doc) => {
        let data = doc.data();
        return data;
      });
      // movies haritasını oluşturun
      const moviePromises = activitiesData.map((activity) =>
        fetch(
          `https://api.themoviedb.org/3/movie/${activity.movieId}?api_key=${API_KEY}&language=tr-TR`
        ).then((response) => response.json())
      );
      const movieResults = await Promise.all(moviePromises); // await burada çalışacak
      const movieMap = movieResults.reduce((acc, currMovie, index) => {
        acc[activitiesData[index].movieId] = currMovie;
        return acc;
      }, {});

      setActivities(activitiesData);
      setMovies(movieMap);
    });

    return () => unsubscribe();
  }, [userId]);

  return (
    <div className={styles.activitiesContainer}>
      <div className={styles.activityList}>
        {activities.map((activity, index) => (
          <div key={index} className={styles.activityItem}>
            {movies[activity.movieId]?.poster_path ? (
              <img
                src={`${POSTER_BASE_URL}${
                  movies[activity.movieId].poster_path
                }`}
                alt={movies[activity.movieId]?.title || "Poster"}
                className={styles.moviePoster}
              />
            ) : null}
            <p>
              <span className={styles.movieTitle}>
                {movies[activity.movieId]?.title || activity.movieId}
              </span>{" "}
              <span className={styles.unimportant}>filmini</span>{" "}
              <span className={styles.date}>
                {formatDateFromTimestamp(activity.timestamp)}
              </span>{" "}
              <span className={styles.unimportant}>tarihinde</span>{" "}
              <span className={styles.actionText}>
                {getActionText(activity.action)}
              </span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UserActivities;
