import React from "react";
import ListCreateForm from "../components/ListCreateForm/ListCreateForm";
import styles from "../styles/ListCreate.module.css";

function ListCreate() {
  return (
    <div className={styles.listCreateContainer}>
      <div className={styles.formWrapper}>
        <ListCreateForm />
      </div>
    </div>
  );
}

export default ListCreate;
