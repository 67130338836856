import React, { useState, useRef } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import styles from "./ListCreateForm.module.css";
import SearchResults from "../SearchResults/SearchResults";
import { db } from "../../firebase/config";
import { collection, addDoc, doc, getDoc, setDoc } from "firebase/firestore";
import AddedMoviesList from "../AddedMoviesList/AddedMoviesList";
import { BsFillPeopleFill, BsFillLockFill } from "react-icons/bs";
import { FaRegCircle, FaCheckCircle } from "react-icons/fa";
import { API_KEY } from "../../config";
import { updateDoc, arrayUnion } from "firebase/firestore";

function ListCreateForm(props) {
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [addedMovies, setAddedMovies] = useState([]);
  const [moviesData, setMoviesData] = useState({});
  const [isPublic, setIsPublic] = useState(true);
  const { user } = useAuthContext();

  const searchContainerRef = useRef(null);

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setNewDescription(e.target.value);
  };

  const addMovieToList = (movie) => {
    setAddedMovies((currentMovies) => [...currentMovies, movie]);
    fetchMovieData(movie.id);
    setQuery("");
  };

  const fetchMovieData = async (id) => {
    try {
      const response = await fetch(
        `https://api.themoviedb.org/3/movie/${id}?api_key=${API_KEY}`
      );
      const data = await response.json();
      setMoviesData((currentData) => ({ ...currentData, [id]: data }));
    } catch (error) {
      console.error("Failed to fetch movie data:", error);
    }
  };

  const handleVisibilityChange = (isPublicSelected) => {
    setIsPublic(isPublicSelected);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const currentDate = new Date().toISOString(); // Mevcut tarihi ISO formatında alıyoruz

    // Yeni liste oluştur
    const newListRef = await addDoc(collection(db, "listeler"), {
      ad: newName,
      aciklama: newDescription,
      filmler: addedMovies.map((movie) => ({
        id: movie.id,
        eklenmeTarihi: currentDate,
      })), // Her film için eklenme tarihini ekliyoruz
      olusturan: user.uid,
      takipEdenler: [],
      herkeseAcik: isPublic,
      olusturmaTarihi: currentDate,
    });

    // Tüm filmleri `filmler` koleksiyonuna ekleme işlemleri
    const updatePromises = addedMovies.map(async (movie) => {
      const filmRef = doc(db, "filmler", movie.id.toString());
      const filmSnap = await getDoc(filmRef);

      if (filmSnap.exists()) {
        await updateDoc(filmRef, {
          listeIdler: arrayUnion(newListRef.id),
        });
      } else {
        // Burada `moviesData`'dan ilgili film bilgilerini kullanabilirsiniz
        const movieData = moviesData[movie.id];
        if (movieData) {
          await setDoc(filmRef, {
            ad: movieData.title,
            uid: movieData.id,
            listeIdler: [newListRef.id],
          });
        }
      }
    });

    // Tüm asenkron işlemlerin tamamlanmasını bekle
    await Promise.all(updatePromises);

    alert(newName + " isimli liste oluşturuldu");

    setNewName("");
    setNewDescription("");
    setAddedMovies([]);
    setIsPublic(true);
  };

  const [query, setQuery] = useState("");
  const [searchedMovies, setSearchedMovies] = useState([]);
  const [searchFocus, setSearchFocus] = useState(false);

  const searchMovie = async (searchQuery) => {
    const url = `https://api.themoviedb.org/3/search/movie?api_key=${API_KEY}&query=${query}`;
    const res = await fetch(url);
    const data = await res.json();
    setSearchedMovies(data.results);
  };

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
    if (e.target.value) {
      searchMovie(e.target.value);
    } else {
      setSearchedMovies([]);
    }
  };

  const handleSearchFocus = () => {
    setSearchFocus(true);
  };

  const handleSearchBlur = () => {
    setTimeout(() => setSearchFocus(false), 200);
  };

  const addedMoviesWithIndex = addedMovies.map((movie, index) => ({
    ...movie,
    index: index + 1,
  }));

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <label className={styles.formLabel}>
          <span className={styles.labelText}>Liste İsmi:</span>
          <input
            required
            type="text"
            onChange={handleNameChange}
            value={newName}
            className={styles.nameInput}
          />
        </label>
        <label className={styles.formLabel}>
          <span className={styles.labelText}>Açıklama:</span>
          <textarea
            rows="2" // Adjusted the rows here
            onChange={handleDescriptionChange}
            value={newDescription}
            className={styles.descInput}
          />
        </label>
        <div className={styles.searchContainer}>
          <span className={styles.labelText}>Film Ekle:</span>
          <div className={styles.searchForm}>
            <input
              type="search"
              name="query"
              value={query}
              onChange={handleSearchChange} // Adjusted the function here
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
              className={styles.searchInput}
            />
          </div>
          {searchFocus && query && (
            <SearchResults
              reference={searchContainerRef}
              movies={searchedMovies}
              onAddMovie={addMovieToList}
            />
          )}
        </div>
        <div className={styles.visibilityContainer}>
          <span className={styles.labelText}>Kimler görebilir:</span>
          <div className={styles.visibilityOptions}>
            <div
              onClick={() => handleVisibilityChange(true)}
              className={
                isPublic
                  ? styles.visibilityOptionActive
                  : styles.visibilityOption
              }
            >
              {isPublic ? (
                <FaCheckCircle className={styles.visibilityIcon} />
              ) : (
                <FaRegCircle className={styles.visibilityIcon} />
              )}
              <BsFillPeopleFill className={styles.visibilityIcon} />
              <span>Herkese açık</span>
            </div>
            <div
              onClick={() => handleVisibilityChange(false)}
              className={
                !isPublic
                  ? styles.visibilityOptionActive
                  : styles.visibilityOption
              }
            >
              {!isPublic ? (
                <FaCheckCircle className={styles.visibilityIcon} />
              ) : (
                <FaRegCircle className={styles.visibilityIcon} />
              )}
              <BsFillLockFill className={styles.visibilityIcon} />
              <span>Özel</span>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.createButton} type="submit">
            Oluştur
          </button>
          <button
            className={styles.closeButton}
            onClick={() => props.setIsCreatingList(false)}
          >
            Kapat
          </button>
        </div>
        <div className={styles.moviePostersContainer}>
          <AddedMoviesList
            addedMovies={addedMoviesWithIndex}
            moviesData={moviesData}
          />
        </div>
      </form>
    </div>
  );
}

export default ListCreateForm;
