import React from "react";
import { Link } from "react-router-dom";
import styles from "./AddedMoviesList.module.css";

const AddedMoviesList = ({ addedMovies, moviesData }) => {
  const BASE_IMG_URL = "https://image.tmdb.org/t/p/w500";

  const listedekiFilmler = addedMovies.map((movie) => {
    const movieData = moviesData[movie.id];
    if (!movieData) return null;

    return (
      <div key={movie.id} className={styles.movieItem}>
        <div className={styles.movieNumber}>{movie.index}.</div>
        <Link to={`/movies/${movie.id}`}>
          <img
            className={styles.moviePoster}
            src={`${BASE_IMG_URL}${movieData.poster_path}`}
            alt={movieData.title}
          />
        </Link>
        <div className={styles.movieInfo}>
          <div className={styles.movieTitle}>{movieData.title}</div>
          <div className={styles.movieReleaseDate}>
            {movieData.release_date}
          </div>
        </div>
      </div>
    );
  });

  return <div className={styles.addedMoviesList}>{listedekiFilmler}</div>;
};

export default AddedMoviesList;
