import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { API_KEY } from "../config";
import styles from "../styles/Person.module.css";

export default function Person() {
  const { id } = useParams();
  const [person, setPerson] = useState(null);
  const [credits, setCredits] = useState({ cast: [], crew: {} });

  useEffect(() => {
    if (id) {
      fetch(`https://api.themoviedb.org/3/person/${id}?api_key=${API_KEY}`)
        .then((response) => response.json())
        .then((data) => {
          setPerson(data);
        })
        .catch((error) => console.log(error));

      fetch(
        `https://api.themoviedb.org/3/person/${id}/movie_credits?api_key=${API_KEY}`
      )
        .then((response) => response.json())
        .then((data) => {
          const sortedCast = data.cast.sort(
            (a, b) => new Date(b.release_date) - new Date(a.release_date)
          );

          const groupedCrew = data.crew.reduce((acc, movie) => {
            if (!acc[movie.job]) acc[movie.job] = [];
            acc[movie.job].push(movie);
            return acc;
          }, {});

          setCredits({ cast: sortedCast, crew: groupedCrew });
        })
        .catch((error) => console.log(error));
    }
  }, [id]);

  if (!person) {
    return <div className={styles.loading}>Loading...</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.topSection}>
        <div className={styles.imageSection}>
          <img
            src={`https://image.tmdb.org/t/p/w500${person.profile_path}`}
            alt={person.name}
            className={styles.profileImage}
          />
        </div>
        <div className={styles.infoSection}>
          <h1 className={styles.name}>{person.name}</h1>
          <p className={styles.biography}>{person.biography}</p>
          <p>
            <span className={styles.infoLabel}>Doğum Tarihi:</span>{" "}
            {person.birthday}
          </p>
          <p>
            <span className={styles.infoLabel}>Doğum Yeri:</span>{" "}
            {person.place_of_birth}
          </p>
        </div>
      </div>

      <h2 className={styles.movieTitle}>Yer aldığı filmler:</h2>

      <h3>Oyuncu</h3>
      <div className={styles.movieList}>
        {credits.cast.map((credit, index) => (
          <div key={index} className={styles.movieItem}>
            <Link
              to={`/movies/${credit.id}`}
              state={{ movie: credit }}
              className={styles.movieLink}
            >
              <img
                src={`https://image.tmdb.org/t/p/w500${credit.poster_path}`}
                alt={credit.title}
                className={styles.creditImage}
              />
              <h3 className={styles.creditTitle}>
                {credit.title}{" "}
                {credit.release_date
                  ? `(${new Date(credit.release_date).getFullYear()})`
                  : ""}
              </h3>
              <p className={styles.character}>
                {credit.character ? `Karakter: ${credit.character}` : ""}
              </p>
              <p className={styles.vote}>
                Puan: {parseFloat(credit.vote_average).toFixed(1)}
              </p>
            </Link>
          </div>
        ))}
      </div>

      {Object.keys(credits.crew).map((job) => (
        <React.Fragment key={job}>
          <h3>{job}</h3>
          <div className={styles.movieList}>
            {credits.crew[job].map((credit, index) => (
              <div key={index} className={styles.movieItem}>
                <Link
                  to={`/movies/${credit.id}`}
                  state={{ movie: credit }}
                  className={styles.movieLink}
                >
                  <img
                    src={`https://image.tmdb.org/t/p/w500${credit.poster_path}`}
                    alt={credit.title}
                    className={styles.creditImage}
                  />
                  <h3 className={styles.creditTitle}>
                    {credit.title}{" "}
                    {credit.release_date
                      ? `(${new Date(credit.release_date).getFullYear()})`
                      : ""}
                  </h3>
                  <p className={styles.character}>{credit.job}</p>
                  <p className={styles.vote}>
                    Puan: {parseFloat(credit.vote_average).toFixed(1)}
                  </p>
                </Link>
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
