import React from "react";
import styles from "./DetailedUserCard.module.css";
import DefaultAvatar from "../../assets/unisex_avatar.jpg";

const DetailedUserCard = ({ user }) => {
  const { displayName, avatar, watchedMovies, followedLists, followedUsers } =
    user;

  return (
    <div className={styles.detailedUserCard}>
      <img
        src={avatar || DefaultAvatar}
        className={styles.userAvatar}
        alt="user-avatar"
      />
      <h4 className={styles.userName}>{displayName}</h4>
      <p>
        <span className={styles.label}>İzlediği Filmler:</span>{" "}
        <span className={styles.value}>{watchedMovies.length}</span>
      </p>
      <p>
        <span className={styles.label}>Listeler:</span>{" "}
        <span className={styles.value}>{followedLists.length}</span>
      </p>
      <p>
        <span className={styles.label}>Takip Ettikleri:</span>{" "}
        <span className={styles.value}>{followedUsers.length}</span>
      </p>
    </div>
  );
};

export default DetailedUserCard;
