import React, { useState, useEffect } from "react";
import { API_KEY } from "../../config";
import axios from "axios";
import styles from "./TrailerScroller.module.css";
import { Link } from "react-router-dom";

const TrailerScroller = () => {
  const [trailers, setTrailers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [currentVideoKey, setCurrentVideoKey] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState("popular");

  const handlePlayClick = (videoKey) => {
    setCurrentVideoKey(videoKey);
    setShowModal(true);
  };

  useEffect(() => {
    const fetchTrailers = async () => {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/movie/${selectedCategory}?api_key=${API_KEY}&language=tr`
        );

        const movies = response.data.results;
        const trailerPromises = movies.map(async (movie) => {
          const trailerResponse = await axios.get(
            `https://api.themoviedb.org/3/movie/${movie.id}/videos?api_key=${API_KEY}&language=tr`
          );
          const trailer = trailerResponse.data.results[0];
          // console.log("trailer:", trailer);
          // console.log("movie:", movie);
          if (trailer) {
            return {
              id: trailer.id,
              movie: movie,
              movieId: movie.id,
              title: movie.title,
              bg_image: `https://image.tmdb.org/t/p/w500${movie.backdrop_path}`,
              imageSrc: `https://image.tmdb.org/t/p/w500${movie.poster_path}`,
              link: `https://www.youtube.com/watch?v=${trailer.key}`,
              description: trailer.name,
            };
          }
          return null;
        });

        const trailersData = await Promise.all(trailerPromises);
        setTrailers(trailersData.filter((trailer) => trailer));
        setLoading(false);
      } catch (error) {
        console.error("Fragmanlar yüklenirken bir hata oluştu:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchTrailers();
  }, [selectedCategory]);

  if (loading) return <p>Yükleniyor...</p>;
  if (error) return <p>Hata oluştu: {error.message}</p>;

  return (
    <div className={styles.trailer_scroller}>
      <div className={styles.headerWrapper}>
        <h2>Son Fragmanlar</h2>
        <div className={styles.buttonGroup}>
          <button onClick={() => setSelectedCategory("upcoming")}>
            Yakında
          </button>
          <button onClick={() => setSelectedCategory("now_playing")}>
            Vizyonda
          </button>
          <button onClick={() => setSelectedCategory("top_rated")}>
            En Yüksek Puanlılar
          </button>
        </div>
      </div>
      <div className={styles.scroller_wrap}>
        {trailers.map((trailer) => (
          <div key={trailer.id} className={styles.card}>
            <div
              onClick={() => handlePlayClick(trailer.link.split("=")[1])} // Burada sadece YouTube video anahtarını alıyoruz
              title={trailer.title}
              className={styles.trailer_link}
            >
              <img loading="lazy" src={trailer.bg_image} alt={trailer.title} />
              <div className={styles.play_button}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 5V19L19 12L8 5Z" fill="white" />
                </svg>
              </div>
            </div>
            <Link
              to={`/movies/${trailer.movieId}`}
              state={{ movie: trailer.movieObject }}
              className={styles.linkStyle}
            >
              <h3>{trailer.title}</h3>
              <p>{trailer.description}</p>
            </Link>
          </div>
        ))}
      </div>
      {showModal && (
        <div className={styles.modal}>
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${currentVideoKey}`}
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <button onClick={() => setShowModal(false)}>Kapat</button>
        </div>
      )}
    </div>
  );
};

export default TrailerScroller;
