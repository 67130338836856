import React from "react";
import { API_KEY } from "../config";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import ListViewItem from "../components/ListViewItem/ListViewItem";
import { useAuthContext } from "../hooks/useAuthContext";
import { useUserData } from "../hooks/useUserData";
import { useParams } from "react-router-dom";
import styles from "../styles/ListViewPage.module.css";
import AddMovieSearchResults from "../components/AddMovieSearchResults/AddMovieSearchResults";
import UserCard from "../components/UserCard/UserCard";
import UserAvatarCard from "../components/UserAvatarCard/UserAvatarCard";
import {
  faSortAmountDown,
  faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";

// firebase imports
import { db } from "../firebase/config";
import {
  query,
  where,
  collection,
  getDoc,
  getDocs,
  doc,
  addDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faStar, faCheck } from "@fortawesome/free-solid-svg-icons";

function ListViewPage() {
  const location = useLocation();
  const ekliFilmler = location.state?.list.filmler;
  const [listCreator, setListCreator] = useState(null);
  const [followers, setFollowers] = useState([]);
  const [watchedMovies, setWatchedMovies] = useState([]);
  const [list, setList] = useState(location.state?.list || null);

  const { listId } = useParams();

  const [ekliFilmlerMovies, setEkliFilmlerMovies] = useState([]);

  const [watchedMoviesInList, setWatchedMoviesInList] = useState([]);

  const handleMovieWatchedStatus = (movieId, isWatched) => {
    if (isWatched && !watchedMoviesInList.includes(movieId)) {
      setWatchedMoviesInList((prev) => [...prev, movieId]);
    } else if (!isWatched && watchedMoviesInList.includes(movieId)) {
      setWatchedMoviesInList((prev) => prev.filter((id) => id !== movieId));
    }
  };

  // Sıralama
  // Sıralama için başlangıç değeri
  const [sortType, setSortType] = useState({
    key: "vote_average",
    direction: "desc",
  });

  const [sortedMovies, setSortedMovies] = useState([]);

  // Sıralama türünü güncelleyen fonksiyon
  const handleSortTypeChange = (newSortValue) => {
    setSortType({ key: "vote_average", direction: newSortValue });
  };

  // ekliFilmlerMovies ve sortType değiştiğinde tetiklenecek
  useEffect(() => {
    let sortedList;
    switch (sortType.direction) {
      case "asc":
        sortedList = [...ekliFilmlerMovies].sort(
          (a, b) => a.vote_average - b.vote_average
        );
        break;
      case "desc":
        sortedList = [...ekliFilmlerMovies].sort(
          (a, b) => b.vote_average - a.vote_average
        );
        break;
      case "date_desc":
        sortedList = [...ekliFilmlerMovies].sort(
          (a, b) => new Date(b.release_date) - new Date(a.release_date)
        );
        break;
      case "date_asc":
        sortedList = [...ekliFilmlerMovies].sort(
          (a, b) => new Date(a.release_date) - new Date(b.release_date)
        );
        break;
      default:
        sortedList = ekliFilmlerMovies;
    }
    setSortedMovies(sortedList);
  }, [ekliFilmlerMovies, sortType]);

  // sortType'ı değiştiren fonksiyon
  const handleSortChange = (newSortKey) => {
    setSortType((currentSortType) => {
      let newDirection = "asc";
      if (currentSortType.key === newSortKey) {
        // If the sort key is the same, toggle the sort direction
        newDirection = currentSortType.direction === "asc" ? "desc" : "asc";
      }
      return { key: newSortKey, direction: newDirection };
    });
  };

  const getMovieById = async (movie_id) => {
    try {
      const response = await axios.get(
        `https://api.themoviedb.org/3/movie/${movie_id}?api_key=${API_KEY}&language=tr`
      );
      return response.data;
    } catch (error) {
      console.error(`Error fetching movie with id ${movie_id}: `, error);
    }
  };

  useEffect(() => {
    const fetchEkliFilmlerMovies = async () => {
      const moviesPromises = ekliFilmler
        .filter((movieItem) => movieItem.id !== undefined)
        .map((movieItem) => getMovieById(movieItem.id));

      const movies = await Promise.all(moviesPromises);
      setEkliFilmlerMovies(movies);
    };

    fetchEkliFilmlerMovies();
  }, [ekliFilmler]);

  useEffect(() => {
    const fetchListCreator = async () => {
      const userRef = doc(db, "users", list.olusturan);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        setListCreator(userSnap.data());
      } else {
        console.log("No such user!");
      }
    };

    fetchListCreator();
  }, [list]);

  const fetchUserById = async (userId) => {
    const userDoc = await getDoc(doc(db, "users", userId));
    return userDoc.exists() ? { id: userDoc.id, ...userDoc.data() } : null;
  };

  const [followersData, setFollowersData] = useState([]);

  useEffect(() => {
    const fetchFollowersData = async () => {
      const followersPromises = list.takipEdenler.map((followerId) =>
        fetchUserById(followerId)
      );
      const resolvedFollowersData = await Promise.all(followersPromises);
      setFollowersData(resolvedFollowersData);
    };

    fetchFollowersData();
  }, [list]);

  const { user } = useAuthContext();
  const { userData, loading } = useUserData(user ? user.uid : null);

  const [lists, setLists] = useState([]);

  const [movies, setMovies] = useState([]);

  const [addedToFollowedLists, setAddedToFollowedLists] = useState(false);

  const getMovies = async () => {
    const response = await axios.get(
      `https://api.themoviedb.org/3/movie/${list.tag}?api_key=${API_KEY}&language=tr`
    );
    return response.data.results;
  };

  useEffect(() => {
    if (userData && userData.followedLists) {
      const isInFollowedLists = userData.followedLists.some(
        (followedList) => followedList === list.id
      );
      setAddedToFollowedLists(isInFollowedLists);
    }
  }, [userData, list]);

  const handleAddToFollow = async (e) => {
    e.preventDefault();

    // kullanıcı verilerini güncelle
    const userRef = doc(db, "users", user.uid);
    await updateDoc(userRef, {
      followedLists: arrayUnion(list.id),
    });

    // liste verilerini güncelle
    const listRef = doc(db, "listeler", list.id);
    await updateDoc(listRef, {
      takipEdenler: arrayUnion(user.uid),
    });

    setAddedToFollowedLists(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const movies = await getMovies();
      setMovies(movies);
    };
    fetchData();
  }, []);

  // Film arayıp ekleme
  const [query, setQuery] = useState("");
  const [searchedMovies, setSearchedMovies] = useState([]);
  const [searchFocus, setSearchFocus] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const searchContainerRef = useRef(null);

  const handleAddFilmToList = () => {
    setShowSearch(true);
  };

  const searchMovie = async (searchQuery) => {
    const url = `https://api.themoviedb.org/3/search/movie?api_key=bcc4ff10c2939665232d75d8bf0ec093&query=${query}`;
    const res = await fetch(url);
    const data = await res.json();
    setSearchedMovies(data.results);
  };

  const addMovieToListDatabase = async (movie) => {
    const currentDate = new Date().toISOString();
    const listRef = doc(db, "listeler", list.id);

    try {
      await updateDoc(listRef, {
        filmler: arrayUnion({ id: movie.id, eklenmeTarihi: currentDate }),
      });

      // Filmi veritabanına ekledikten sonra state'i güncelle
      const updatedMovie = await getMovieById(movie.id);
      setEkliFilmlerMovies((prevMovies) => [...prevMovies, updatedMovie]);

      setQuery("");
    } catch (error) {
      console.error("Error adding movie to list: ", error);
    }
  };

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
    if (e.target.value) {
      searchMovie(e.target.value);
    } else {
      setSearchedMovies([]);
    }
  };

  const handleSearchFocus = () => {
    setSearchFocus(true);
  };

  const handleSearchBlur = () => {
    setTimeout(() => setSearchFocus(false), 200);
  };

  useEffect(() => {
    if (listId && !list) {
      // URL'den gelen listId varsa ve list state'i henüz set edilmediyse
      const fetchListData = async () => {
        const listRef = doc(db, "listeler", listId);
        const listSnap = await getDoc(listRef);

        if (listSnap.exists()) {
          const listData = listSnap.data();
          setList({ id: listSnap.id, ...listData });

          // Ekli filmleri çek ve güncelle
          const moviesPromises = listData.filmler.map((movieItem) =>
            getMovieById(movieItem.id)
          );
          const movies = await Promise.all(moviesPromises);
          setEkliFilmlerMovies(movies);
        } else {
          console.log("Liste bulunamadı");
        }
      };

      fetchListData();
    }
  }, [listId, list]);

  if (!list) {
    return <div>Yükleniyor...</div>; // Veya istediğiniz başka bir yükleme ekranı
  }

  return (
    <div className={styles.listViewContainer}>
      <div className={styles.listHeader}>
        <div className={styles.listInfo}>
          <h1 className={styles.listTitle}>{list.ad}</h1>
          <p className={styles.listDescription}>{list.aciklama}</p>
        </div>
        {user ? (
          <p className={styles.movieCountInfo}>
            <span className={styles.dynamic}>{ekliFilmlerMovies.length}</span>{" "}
            filmden{" "}
            <span className={styles.dynamic}>{watchedMoviesInList.length}</span>{" "}
            tanesini izlediniz.
          </p>
        ) : (
          <p className={styles.movieCountInfo}>
            Bu listeden kaç tane filmi izlediğinizi görmek için giriş yapınız.
          </p>
        )}
        {user && (
          <div className={styles.buttonsContainer}>
            <button
              className={`${styles.listAddButton} ${
                addedToFollowedLists ? "added" : ""
              } `}
              onClick={handleAddFilmToList}
            >
              <FontAwesomeIcon icon={faPlus} />
              <span>Listeye film ekle</span>
            </button>
            <button
              className={`${styles.listAddButton} ${
                addedToFollowedLists ? styles.added : ""
              } `}
              onClick={handleAddToFollow}
            >
              <FontAwesomeIcon icon={faCheck} />
              <span>Listeyi takip et</span>
            </button>
          </div>
        )}
      </div>
      <div className={styles.creatorContainer}>
        <span>Oluşturan: </span>
        {listCreator && (
          <Link
            className={styles.listCreatorUser}
            to={`/users/${list.olusturan}`}
            state={{ user: user }}
          >
            <UserCard user={listCreator} />
          </Link>
        )}
        {user ? (
          <div className={styles.followersContainer}>
            <span>Takip edenler: </span>
            {followersData.slice(0, 10).map((follower) => {
              return (
                follower && (
                  <Link
                    className={styles.listCreatorUser}
                    to={`/users/${follower.id}`}
                    state={{ user: user }}
                    key={follower.id}
                  >
                    <UserAvatarCard key={follower.id} user={follower} />
                  </Link>
                )
              );
            })}
          </div>
        ) : (
          <p>Bu bölümü görmek için lütfen giriş yapınız.</p>
        )}
      </div>

      {showSearch && (
        <div className={styles.searchContainer}>
          <span className={styles.labelText}>Film Ekle:</span>
          <div className={styles.searchForm}>
            <input
              type="search"
              name="query"
              value={query}
              onChange={handleSearchChange}
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
              className={styles.searchInput}
            />
          </div>
          {searchFocus && query && (
            <AddMovieSearchResults
              reference={searchContainerRef}
              movies={searchedMovies}
              onAddMovie={addMovieToListDatabase}
            />
          )}
        </div>
      )}
      <div className={styles.sortContainer}>
        <div className={styles.sortButton}>
          <span>Sıralama: </span>
          <select onChange={(e) => handleSortTypeChange(e.target.value)}>
            <option value="desc">Puana göre azalan</option>
            <option value="asc">Puana göre artan</option>
            <option value="date_desc">Tarihe göre azalan</option>
            <option value="date_asc">Tarihe göre artan</option>
          </select>
        </div>
      </div>

      {sortedMovies.map((movie, index) => (
        <div className={`${styles.listViewItem} listViewItem`} key={movie.id}>
          <div className={`${styles.itemNumber} itemNumber`}>{index + 1}</div>
          <div className={`${styles.movieDetails} movieDetails`}>
            <ListViewItem
              movie={movie}
              key={movie.id}
              onMovieWatchedStatus={handleMovieWatchedStatus}
            />
          </div>
        </div>
      ))}

      {movies.map((movie, index) => (
        <div className={`${styles.listViewItem} listViewItem`} key={movie.id}>
          <div className={`${styles.itemNumber} itemNumber`}>
            {index + 1 + ekliFilmlerMovies.length}
          </div>
          <div className={`${styles.movieDetails} movieDetails`}>
            <ListViewItem
              movie={movie}
              onMovieWatchedStatus={handleMovieWatchedStatus}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ListViewPage;
