import React, { useState, useEffect, useRef } from "react";
import HomeListsViewCard from "../HomeListsViewCard/HomeListsViewCard";
import styles from "./HomeListsView.module.css";
import ListCreateForm from "../ListCreateForm/ListCreateForm";
import { Link } from "react-router-dom";

import { useAuthContext } from "../../hooks/useAuthContext";
import { useUserData } from "../../hooks/useUserData";
import { API_KEY } from "../../config";
import axios from "axios";

function HomeListsView({ lists }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isCreatingList, setIsCreatingList] = useState(false);
  const scrollContainerRef = useRef();
  const [watchedPercentages, setWatchedPercentages] = useState([]);
  const [filmler, setFilmler] = useState([]);

  const { user } = useAuthContext();
  const allUserData = useUserData(user?.uid); // always call the hook
  const { userData, loading } = user
    ? allUserData
    : { userData: null, loading: false };

  const getMovieById = async (id) => {
    const response = await axios.get(
      `https://api.themoviedb.org/3/movie/${id}?api_key=${API_KEY}&language=tr`
    );
    return response.data;
  };

  const getMovies = async (tag) => {
    if (filmler.length > 0) {
      const movies = await Promise.all(
        filmler.slice(0, 5).map((movie_id) => getMovieById(movie_id))
      );
      return movies;
    } else {
      const response = await axios.get(
        `https://api.themoviedb.org/3/movie/${tag}?api_key=${API_KEY}&language=tr`
      );
      const movies = response.data.results;
      setFilmler(movies.map((movie) => movie.id));
      return movies;
    }
  };

  useEffect(() => {
    const calculateWatchedPercentages = async () => {
      if (!userData) return;
      const percentages = await Promise.all(
        lists?.map(async (list) => {
          let moviesInList = list.filmler;

          // Liste içinde film yoksa, belirtilen tag'e göre ilk 20 filmi getirin.
          if (moviesInList.length === 0 && list.tag) {
            const movies = await getMovies(list.tag);
            moviesInList = movies.map((movie) => movie.id);
          }

          const watchedMoviesInList = moviesInList.filter((id) =>
            userData?.watchedMovies.includes(id)
          );
          const watchedPercentage =
            (watchedMoviesInList.length / (moviesInList.length || 1)) * 100;
          return watchedPercentage;
        }) || []
      );

      setWatchedPercentages(percentages);
    };

    if (userData && lists) {
      calculateWatchedPercentages();
    }
  }, [lists, userData, filmler]);

  useEffect(() => {
    if (lists && lists.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          if (prevIndex + 1 >= lists.length) {
            return 0;
          } else {
            return prevIndex + 1;
          }
        });
        scrollContainerRef.current.scrollTop += 50; // height of each list name element
      }, 15000); // 10000 ms = 10 saniye
      return () => clearInterval(interval);
    }
  }, [lists]);

  const handleListItemClick = (index) => {
    setIsCreatingList(false);
    setCurrentIndex(index);
  };

  const handleCreateListClick = () => {
    setIsCreatingList(true);
  };

  return (
    <div className={styles.listContainer}>
      <div className={styles.listSection}>
        <div className={styles.listNames} ref={scrollContainerRef}>
          <h2 className={styles.listTitle}>Listeler</h2>
          {lists && userData ? (
            lists.map((list, index) => (
              <div
                className={`${styles.listName} ${
                  index === currentIndex ? styles.active : ""
                }`}
                style={{
                  backgroundImage: `linear-gradient(90deg, rgba(0,128,0,0.6) ${watchedPercentages[index]}%, #494c55 ${watchedPercentages[index]}%)`,
                  position: "relative",
                }}
                onClick={() => handleListItemClick(index)}
                key={list.id}
              >
                {index === currentIndex && (
                  <div className={styles.activeIcon}></div>
                )}
                {list.ad}
              </div>
            ))
          ) : user ? (
            <div>Listeler yükleniyor...</div>
          ) : (
            <Link to="/login" className={styles.loginPrompt}>
              Listeleri görebilmek için giriş yapınız
            </Link>
          )}
        </div>
        <button
          className={styles.createListButton}
          onClick={handleCreateListClick}
        >
          <Link to="/listCreate">Liste Oluştur</Link>
        </button>
      </div>
      <div className={styles.cardContainer}>
        {isCreatingList ? (
          <ListCreateForm setIsCreatingList={setIsCreatingList} />
        ) : lists && lists.length > 0 ? (
          <HomeListsViewCard
            key={lists[currentIndex].id}
            list={lists[currentIndex]}
            watchedPercentage={watchedPercentages[currentIndex]}
          />
        ) : null}
      </div>
    </div>
  );
}

export default HomeListsView;
