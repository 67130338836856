import { useEffect, useState } from "react";
import { API_KEY } from "../config";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import { useUserData } from "../hooks/useUserData";
import { Link } from "react-router-dom";

import { useCollection } from "../hooks/useCollection";
import ListSelect from "../components/ListSelect/ListSelect";

import ListViewItem from "../components/ListViewItem/ListViewItem";
import AccountListView from "../components/AccountListView/AccountListView";
import AccountMovieListViewItem from "../components/AccountMovieListViewItem/AccountMovieListViewItem";
import AccountRecentActivities from "../components/AccountRecentActivities/AccountRecentActivities";

import MovieCard from "../components/MovieCard/MovieCard";
import UserCard from "../components/UserCard/UserCard";

import Avatar from "../assets/unisex_avatar.jpg";
import { FaArrowRight } from "react-icons/fa";

import styles from "../styles/Account.module.css";

export default function Account() {
  const { user } = useAuthContext();
  const { userData, loading } = useUserData(user.uid);

  const { documents: lists } = useCollection("listeler");
  const { documents: users } = useCollection("users");

  const [movies, setMovies] = useState({});
  const [activeList, setActiveList] = useState("watchList");

  const watchListLength =
    userData && userData.watchList ? userData.watchList.length : 0;
  const watchedMoviesLength =
    userData && userData.watchedMovies ? userData.watchedMovies.length : 0;

  const followersLength =
    userData && userData.followers ? userData.followers.length : 0;
  const followingLength =
    userData && userData.following ? userData.following.length : 0;

  const getMovieById = async (movie_id) => {
    const response = await axios.get(
      `https://api.themoviedb.org/3/movie/${movie_id}?api_key=${API_KEY}&language=tr`
    );
    return response.data;
  };

  useEffect(() => {
    const fetchMovies = async () => {
      const movieLists = {
        watchedMovies: userData.watchedMovies,
        watchList: userData.watchList,
      };
      const fetchedMovies = {};
      for (const list in movieLists) {
        if (movieLists[list]) {
          for (const movie_id of movieLists[list]) {
            const movie = await getMovieById(movie_id);
            fetchedMovies[movie_id] = movie;
          }
        }
      }
      setMovies(fetchedMovies);
    };
    if (userData && lists) {
      fetchMovies();
    }
  }, [userData, lists]);

  useEffect(() => {
    if (users) {
      kullanicilariGetir();
    }
  }, [users]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const listeyiGetir = (listName) => {
    const movieList = userData[listName];
    if (!movieList) {
      return null;
    }
    return movieList.map((movie_id) => {
      const movie = movies[movie_id];
      return (
        movie && (
          <AccountMovieListViewItem
            className="accountMovieCard"
            key={movie_id}
            movie={movie}
          />
        )
      );
    });
  };

  const listedenFilmleriGetir = () => {
    if (!userData.watchedMovies) {
      return null;
    }

    return userData.watchedMovies.map((movie_id) => {
      const movie = movies[movie_id];
      return (
        movie && (
          <AccountMovieListViewItem
            className="accountMovieCard"
            key={movie_id}
            movie={movie}
          />
        )
      );
    });
  };

  const kullanicilariGetir = () => {
    if (!userData.followedUsers || !users) {
      return null;
    }

    return userData.followedUsers.map((user_id) => {
      const matchedUser = users.find((user) => user.id === user_id);

      if (matchedUser) {
        return (
          <Link
            to={`/users/${user_id}`}
            state={{ user: matchedUser }}
            key={user_id}
          >
            <UserCard user={matchedUser} />
          </Link>
        );
      } else {
        return null;
      }
    });
  };

  const takipEdilenListeleriGetir = () => {
    if (!userData.followedLists || !lists) {
      return null;
    }

    return userData.followedLists.map((listId) => {
      const matchedList = lists.find((list) => {
        return list.id.trim() === listId.trim();
      });

      if (matchedList) {
        return (
          <AccountListView key={listId} list={matchedList} tag={lists.tag} />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className={styles.container}>
      {/* Banner */}
      <div className={styles.userInfo}>
        <img
          src={userData.avatar || Avatar}
          className={styles.userAvatar}
          alt="user-avatar-img"
        />
        <div className={styles.userInfoText}>
          <h1 className={styles.userTitle}>{userData.displayName}</h1>
          <p>{watchedMoviesLength} film izledi</p>
          <p>{watchListLength} film sırada</p>
        </div>
      </div>

      {/* <AccountRecentActivities userId={user.uid} /> */}
      {/* Body */}
      <div className={styles.listsFilmsContainer}>
        {/* Lists */}
        <div className={styles.listsContainer}>
          <h2 className={styles.ContainerTitle}>Takip edilen listeler</h2>
          {takipEdilenListeleriGetir()}
        </div>
        {/* Films */}
        <div className={styles.filmsContainer}>
          <div className={styles.FilmsTitle}>
            <span
              className={`${styles.titleOption} ${
                activeList === "watchList" ? styles.selectedTitle : ""
              }`}
              onClick={() => setActiveList("watchList")}
            >
              İzleme Listem ({watchListLength})
            </span>
            <span
              className={`${styles.titleOption} ${
                activeList === "watchedMovies" ? styles.selectedTitle : ""
              }`}
              onClick={() => setActiveList("watchedMovies")}
            >
              İzlediklerim ({watchedMoviesLength})
            </span>
            <Link to="/watchedMovies" title="İzlediklerim sayfasına git">
              <FaArrowRight className={styles.iconButton} />
            </Link>
          </div>
          {listeyiGetir(activeList)}
        </div>
        {/* Users */}
        <div className={styles.usersContainer}>
          <h2 className={styles.ContainerTitle}>Takip edilen kullanıcılar</h2>
          <>{kullanicilariGetir()}</>
        </div>
      </div>
    </div>
  );
}
