import { Link } from "react-router-dom";
import listViewItemStyles from "../ListViewItem/ListViewItem.module.css";
import styles from "./AccountMovieListViewItem.module.css";

function AccountMovieListViewItem({ movie }) {
  const BASE_IMG_URL = "https://image.tmdb.org/t/p/w500";
  const formattedDate = movie.release_date.split("-").reverse().join(".");

  return (
    <Link
      className={`${styles.itemContainer} ${listViewItemStyles.itemContainer}`}
      to={`/movies/${movie.id}`}
      state={{ movie: movie }}
    >
      <div
        className={`${styles.listViewItemContent} ${listViewItemStyles.listViewItemContent}`}
      >
        <img
          className={`${styles.listItemPoster} ${listViewItemStyles.listItemPoster}`}
          src={`${BASE_IMG_URL}${movie.poster_path}`}
          alt="movie-title"
        />
        <div
          className={`${styles.listViewItemDetails} ${listViewItemStyles.listViewItemDetails}`}
        >
          <h2
            className={`${styles.listViewTitle} ${listViewItemStyles.listViewTitle}`}
          >
            {movie.title}
          </h2>
          <h4
            className={`${styles.listViewItemDate} ${listViewItemStyles.listViewItemDate}`}
          >
            Çıkış tarihi: {formattedDate}
          </h4>
          <h3
            className={`${styles.listViewMovieRate} ${listViewItemStyles.listViewMovieRate}`}
          >
            Puan: {movie.vote_average}
          </h3>
        </div>
      </div>
    </Link>
  );
}

export default AccountMovieListViewItem;
