import { useEffect, useState } from "react";
import { API_KEY } from "../config";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import { useUserData } from "../hooks/useUserData";

import WholePageMovieListViewItem from "../components/WholePageMovieListViewItem/WholePageMovieListViewItem";

import styles from "../styles/WatchedMovies.module.css";

export default function WatchedMovies() {
  const { user } = useAuthContext();
  const { userData, loading } = useUserData(user.uid);

  const [movies, setMovies] = useState(new Map());

  const getMovieById = async (movie_id) => {
    const response = await axios.get(
      `https://api.themoviedb.org/3/movie/${movie_id}?api_key=${API_KEY}&language=tr`
    );
    return response.data;
  };

  useEffect(() => {
    const fetchMovies = async () => {
      // userData'nın varlığını ve watchedMovies array'inin varlığını kontrol et
      if (userData && userData.watchedMovies) {
        const moviePromises = userData.watchedMovies.map((movie_id) =>
          getMovieById(movie_id)
        );
        const movieResults = await Promise.all(moviePromises);

        const newMoviesMap = new Map();
        movieResults.forEach((movie) => {
          newMoviesMap.set(movie.id, movie);
        });
        setMovies(newMoviesMap);
      }
    };

    fetchMovies();
  }, [userData]);

  const renderWatchedMovies = () => {
    // userData veya watchedMovies null ise bu mesajı göster
    if (!userData || !userData.watchedMovies) {
      return <div>İzlediğiniz film bulunmamaktadır.</div>;
    }

    return userData.watchedMovies.map((movie_id, index) => {
      const movie = movies.get(movie_id);
      return (
        movie && (
          <WholePageMovieListViewItem
            className="watchedMovieCard"
            index={index}
            key={movie_id}
            movie={movie}
          />
        )
      );
    });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        İzlediklerim (
        {userData && userData.watchedMovies ? userData.watchedMovies.length : 0}
        )
      </h1>
      <div className={styles.moviesList}>{renderWatchedMovies()}</div>
    </div>
  );
}
