import React from "react";
import styles from "./UserCard.module.css";
import DefaultAvatar from "../../assets/unisex_avatar.jpg"; // Yolu düzeltmeyi unutmayın!

const UserCard = ({ user }) => {
  const { displayName, avatar } = user;
  console.log("Kullanıcı: " + user.displayName);

  return (
    <div className={styles.userCard}>
      <img
        src={avatar || DefaultAvatar}
        className={styles.userAvatar}
        alt="user-avatar"
      />
      <h4 className={styles.userName}>{displayName}</h4>
    </div>
  );
};

export default UserCard;
