export default function convertLanguageCodesToNames(languageCode) {
  const languageMap = {
    en: "İngilizce",
    ru: "Rusça",
    tr: "Türkçe",
    de: "Almanca",
    fr: "Fransızca",
    it: "İtalyanca",
    es: "İspanyolca",
    pt: "Portekizce",
    nl: "Hollanda Dili",
    ja: "Japonca",
    zh: "Çince",
    ko: "Korece",
    ar: "Arapça",
    el: "Yunanca",
    sv: "İsveçce",
    da: "Danca",
    fi: "Fince",
    no: "Norveçce",
    pl: "Lehçe",
    cs: "Çekce",
    hu: "Macarca",
    ro: "Romence",
    fa: "Farsça",
  };

  return languageMap[languageCode] || languageCode;
}
