import React from "react";
import styles from "./UserAvatarCard.module.css";
import DefaultAvatar from "../../assets/unisex_avatar.jpg"; // Yolu düzeltmeyi unutmayın!

const UserAvatarCard = ({ user }) => {
  const { displayName, avatar } = user;

  return (
    <div className={styles.userAvatarCard}>
      <img
        src={avatar || DefaultAvatar}
        className={styles.userAvatar}
        alt="user-avatar"
        title={displayName}
      />
    </div>
  );
};

export default UserAvatarCard;
