import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAVC1BLHDW01dt4U93qLMS2Z9E-fVO2Vb4",
  authDomain: "filmlisteleri-45075.firebaseapp.com",
  projectId: "filmlisteleri-45075",
  storageBucket: "filmlisteleri-45075.appspot.com",
  messagingSenderId: "587745569468",
  appId: "1:587745569468:web:8dbfb9384d7ffb1c74feb9",
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Export Firebase APIs
export const auth = getAuth(app);
export const storage = getStorage(app);

// Export Firestore APIs
export const db = getFirestore(app);
