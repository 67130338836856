import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import { db } from "../../firebase/config";
import styles from "./LastComments.module.css";
import { API_KEY } from "../../config";

const BASE_IMG_URL = "https://image.tmdb.org/t/p/w500";

const LastComments = () => {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(db, "comments"),
            orderBy("timestamp", "desc"),
            limit(10)
          )
        );
        const commentsArray = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const commentData = doc.data();
            try {
              const movieResponse = await fetch(
                `https://api.themoviedb.org/3/movie/${commentData.movieId}?api_key=${API_KEY}`
              );
              const movieData = await movieResponse.json();
              return {
                id: doc.id,
                ...commentData,
                movie: {
                  title: movieData.title,
                  poster: `${BASE_IMG_URL}${movieData.poster_path}`,
                },
              };
            } catch (error) {
              console.error("Movie fetch error:", error);
              return {
                id: doc.id,
                ...commentData,
                movie: {
                  title: "Unknown",
                  poster: "",
                },
              };
            }
          })
        );
        setComments(commentsArray);
      } catch (error) {
        console.error("Comments fetch error:", error);
      }
    };

    fetchComments();
  }, []);

  return (
    <>
      <h2 className={styles.header}>Son Yorumlar</h2>
      <div className={styles.commentsContainer}>
        {comments.map((comment) => (
          <Link
            to={`/movies/${comment.movieId}`}
            key={comment.id}
            className={styles.comment}
          >
            <div className={styles.userAvatar}>
              <img src={comment.userAvatar} alt="User Avatar" />
              <span className={styles.userName}>{comment.userName}</span>
            </div>
            <div className={styles.commentDetails}>
              <div className={styles.movieInfo}>
                <img
                  src={comment.movie.poster}
                  alt={comment.movie.title}
                  className={styles.moviePoster}
                />
                <div className={styles.infoColumn}>
                  <span className={styles.movieTitle}>
                    {comment.movie.title}
                  </span>
                  <span className={styles.movieComment}>{comment.comment}</span>
                </div>
              </div>
              {/* <div className={styles.commentContent}>
                    <p>{comment.comment}</p>
                    <span className={styles.timestamp}>
                      {new Date(comment.timestamp).toLocaleString()}
                    </span>
                  </div> */}
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default LastComments;
