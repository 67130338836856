import { useEffect, useState } from "react";
import { API_KEY } from "../config";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import { useUserData } from "../hooks/useUserData";

import WholePageMovieListViewItem from "../components/WholePageMovieListViewItem/WholePageMovieListViewItem";

import styles from "../styles/WatchList.module.css";

export default function WatchList() {
  const { user } = useAuthContext();
  const { userData, loading } = useUserData(user.uid);

  const [movies, setMovies] = useState({});

  const getMovieById = async (movie_id) => {
    const response = await axios.get(
      `https://api.themoviedb.org/3/movie/${movie_id}?api_key=${API_KEY}&language=tr`
    );
    return response.data;
  };

  useEffect(() => {
    const fetchMovies = async () => {
      const watchList = userData.watchList;
      const fetchedMovies = {};
      if (watchList) {
        for (const movie_id of watchList) {
          const movie = await getMovieById(movie_id);
          fetchedMovies[movie_id] = movie;
        }
      }
      setMovies(fetchedMovies);
    };
    if (userData) {
      fetchMovies();
    }
  }, [userData]);

  const watchListLength =
    userData && userData.watchList ? userData.watchList.length : 0;

  if (loading) {
    return <div>Loading...</div>;
  }

  const renderWatchList = () => {
    if (!userData.watchList) {
      return <div>İzlediğiniz film bulunmamaktadır.</div>;
    }

    return userData.watchList.map((movie_id, index) => {
      const movie = movies[movie_id];
      return (
        movie && (
          <WholePageMovieListViewItem
            className="watchedMovieCard"
            index={index}
            key={movie_id}
            movie={movie}
          />
        )
      );
    });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>İzleyeceklerim ({watchListLength})</h1>
      <div className={styles.moviesList}>{renderWatchList()}</div>
    </div>
  );
}
