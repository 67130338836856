import { useState } from "react";
import { useLogin } from "../hooks/useLogin";
import { Link } from "react-router-dom";

// styles
import styles from "../styles/Login.module.css";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, isPending, error } = useLogin();

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <form className={styles.authForm} onSubmit={handleSubmit}>
      <h2>Giriş</h2>
      <label className={styles.label}>
        <span>Email:</span>
        <input
          required
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          className={styles.input}
        />
      </label>
      <label className={styles.label}>
        <span>Şifre:</span>
        <input
          required
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          className={styles.input}
        />
      </label>
      {!isPending && <button className={styles.btn}>Giriş</button>}
      {isPending && (
        <button className={styles.btn} disabled>
          Yükleniyor
        </button>
      )}
      {error && <div className={styles.error}>{error}</div>}
      <p className={styles.signupLink}>Hesabınız yok mu? <Link to="/signup">Buradan kayıt olabilirsiniz</Link></p>
    </form>
  );
}
