import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { db } from "../../firebase/config";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { useComments } from "../../hooks/useComments";
import { useUserData } from "../../hooks/useUserData";
import styles from "./MovieComments.module.css";
import DefaultAvatar from "../../assets/unisex_avatar.jpg"; // Yolu düzeltmeyi unutmayın!

export default function MovieComments() {
  const { id } = useParams();
  const { user } = useAuthContext();
  const { userData, loading } = useUserData(user.uid);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const getComments = async () => {
      const commentsRef = collection(db, "comments");
      const q = query(commentsRef, where("movieId", "==", id));
      const querySnapshot = await getDocs(q);
      const commentsData = [];
      querySnapshot.forEach((doc) => {
        commentsData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setComments(commentsData);
    };

    getComments();
  }, [id]);

  const handleSend = async (e) => {
    e.preventDefault();

    const payload = {
      userId: user.uid,
      userName: userData.displayName,
      userAvatar: userData.avatar || DefaultAvatar,
      movieId: id,
      comment,
      timestamp: Date.now(),
    };

    await addDoc(collection(db, "comments"), payload);
    setComment("");
    // After adding a new comment, refresh the comments list
    const updatedComments = [...comments, payload];
    setComments(updatedComments);
  };

  return (
    <div className={styles.comments}>
      <form className={styles.commentForm} onSubmit={handleSend}>
        <h2>Yorum Yaz</h2>
        <textarea
          className={styles.commentInput}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Yorumunuzu yazın..."
        />
        <button type="submit" className={styles.sendButton}>
          Gönder
        </button>
      </form>
      <div className={styles.commentList}>
        <h2>Yorumlar</h2>
        {comments.map((cmt) => (
          <div key={cmt.id} className={styles.commentItem}>
            <div>
              <img
                src={cmt.userAvatar || DefaultAvatar}
                className={styles.userAvatar}
                alt="user-avatar"
              />
              <h3 className={styles.userName}>{cmt.userName || "Anonim"}</h3>
            </div>
            <div className={styles.commentContent}>
              <p>{cmt.comment}</p>
              <p className={styles.commentDate}>
                {new Date(cmt.timestamp).toLocaleDateString("tr-TR") +
                  " " +
                  new Date(cmt.timestamp).toLocaleTimeString("tr-TR")}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
