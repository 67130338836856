import React from "react";
import ListCard from "../ListCard/ListCard";

import styles from "./ListSelect.module.css";

function ListSelect({ lists }) {
  return (
    <div className={styles.listContainer}>
      <h3 className={styles.listSelectTitle}>Favori Listeler</h3>
      <div className={styles.cardContainer}>
        {lists.map((list) => (
          <ListCard key={list.id} list={list} />
        ))}
      </div>
    </div>
  );
}

export default ListSelect;
