import React, { useEffect, useState } from "react";
import HomeListsView from "../components/HomeListsView/HomeListsView";
import HomeUsersView from "../components/HomeUsersView/HomeUsersView";
import HomeFilmsView from "../components/HomeFilmsView/HomeFilmsView";
import Events from "../components/Events/Events";
import { useCollection } from "../hooks/useCollection";
import { useAuthContext } from "../hooks/useAuthContext";
import { API_KEY } from "../config";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import TrailerScroller from "../components/TrailerScroller/TrailerScroller";
import WelcomeComponent from "../components/Home/WelcomeComponent/WelcomeComponent";

// firebase
import { db } from "../firebase/config";
import { collection, getDocs } from "firebase/firestore";

// styles
import styles from "../styles/Home.module.css";

import {
  AiOutlineSearch,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { RiMenu4Line } from "react-icons/ri";
import HomeListsViewForEveryone from "../components/HomeListsViewForEveryone/HomeListsViewForEveryone";
import LastComments from "../components/LastComments/LastComments";
import HomeListsSummaryView from "../components/HomeListsSummaryView/HomeListsSummaryView";

export default function Home() {
  const { user } = useAuthContext();

  const { documents: lists } = useCollection("listeler");
  const { documents: users } = useCollection("users");

  const [movies, setMovies] = useState([]);

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const [showUsers, setShowUsers] = useState(false);

  const getMovies = async () => {
    const response = await axios.get(
      `https://api.themoviedb.org/3/movie/popular?api_key=${API_KEY}&language=tr`
    );
    return response.data.results;
  };

  // Filmler
  useEffect(() => {
    const fetchData = async () => {
      const movies = await getMovies();
      setMovies(movies);
    };
    fetchData();
  }, []);

  return (
    <div className={styles.homeContainer}>
      <div className={styles.listsFilmsContainer}>
        <div>
          <WelcomeComponent />
        </div>
        {/* <div>
          <HomeListsSummaryView lists={lists} />
        </div> */}
        <div className={styles.listsView}>
          <HomeListsViewForEveryone lists={lists} />
          {/* <HomeListsView lists={lists} /> */}
        </div>
        <div className={styles.trailerScroller}>
          <TrailerScroller />
        </div>
        <div>
          <LastComments />
        </div>
        <div className={styles.listsView}>
          <HomeListsView lists={lists} />
        </div>
      </div>
      {/* {isMobile ? (
        // Mobil Görünüm
        <div className={styles.mobileUsersView}>
          <button
            className={styles.toggleUsersButton}
            onClick={() => setShowUsers(!showUsers)}
          >
            {showUsers ? (
              <>
                <AiOutlineEyeInvisible /> Kullanıcıları Gizle
              </>
            ) : (
              <>
                <AiOutlineEye /> Kullanıcıları Göster
              </>
            )}
          </button>

          <div
            className={showUsers ? styles.usersView : styles.usersViewHidden}
          >
            <HomeUsersView users={users} />
          </div>
        </div>
      ) : (
        // Masaüstü Görünüm
        <div className={styles.usersView}>
          <HomeUsersView users={users} />
        </div>
      )} */}
    </div>
  );
}
