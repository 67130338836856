import React from "react";
import { useCollection } from "../hooks/useCollection";
import DetailedUserCard from "../components/DetailedUserCard/DetailedUserCard";
import { Link } from "react-router-dom";
import styles from "../styles/Users.module.css";

function Users() {
  const { documents: users } = useCollection("users");

  return (
    <div className={styles.usersContainer}>
      <h2 className={styles.usersTitle}>Kullanıcılar</h2>
      <div className={styles.usersList}>
        {users &&
          users.map((user) => (
            <Link key={user.id} to={`/users/${user.id}`} state={{ user: user }}>
              <DetailedUserCard user={user} />
            </Link>
          ))}
      </div>
    </div>
  );
}

export default Users;
