import { Link } from "react-router-dom";
import styles from "./WholePageMovieListViewItem.module.css";

function WholePageMovieListViewItem({ movie, index }) {
  console.log("Gelen movie", movie);
  const BASE_IMG_URL = "https://image.tmdb.org/t/p/w500";
  const formattedDate = movie.release_date.split("-").reverse().join(".");

  return (
    <Link
      className={styles.itemContainer}
      to={`/movies/${movie.id}`}
      state={{ movie: movie }}
    >
      <div className={styles.movieIndex}>{index + 1}.</div>
      <img
        className={styles.listItemPoster}
        src={`${BASE_IMG_URL}${movie.poster_path}`}
        alt={movie.title}
      />
      <div className={styles.listViewItemDetails}>
        <h2 className={styles.listViewTitle}>{movie.title}</h2>
        <p className={styles.listViewItemDate}>Çıkış tarihi: {formattedDate}</p>
        <p className={styles.listViewMovieRate}>Puan: {movie.vote_average}</p>
        <p className={styles.movieDirector}>Yönetmen: {movie.director}</p>
      </div>
    </Link>
  );
}

export default WholePageMovieListViewItem;
