import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { API_KEY } from "../config";
import { useAuthContext } from "../hooks/useAuthContext";
import { useUserData } from "../hooks/useUserData";
import MovieComments from "../components/MovieComments/MovieComments";
import convertLanguageCodesToNames from "../utils/convertLanguageCodesToNames";

// firebase imports
import { db } from "../firebase/config";
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faStar, faCheck } from "@fortawesome/free-solid-svg-icons";

import styles from "../styles/SingleMovie.module.css";

export default function SingleMovie() {
  const { id } = useParams();
  const { user } = useAuthContext();
  const { userData, loading } = useUserData(user ? user.uid : null);

  const [movie, setMovie] = useState(null);
  const [trailerId, setTrailerId] = useState(null);
  const [addedToWatchedMovies, setAddedToWatchedMovies] = useState(false);
  const [addedToWatchList, setAddedToWatchList] = useState(false);
  const [director, setDirector] = useState({ name: "", profilePath: "" });
  const [cast, setCast] = useState([]);

  const [watchListHoverText, setWatchListHoverText] = useState(
    "İzleme listesine ekle"
  );
  const [watchedMoviesHoverText, setWatchedMoviesHoverText] =
    useState("İzlediklerime ekle");

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `https://api.themoviedb.org/3/movie/${id}?api_key=${API_KEY}&language=tr-TR`
      );
      const movieData = await response.json();
      setMovie(movieData);

      const videoResponse = await fetch(
        `https://api.themoviedb.org/3/movie/${id}/videos?api_key=${API_KEY}&language=tr-TR`
      );
      const videoData = await videoResponse.json();
      const trailer = videoData.results.find(
        (video) => video.type === "Trailer" && video.site === "YouTube"
      );
      if (trailer) {
        setTrailerId(trailer.key);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (movie && userData) {
      const isAddedToWatchList = userData.watchList?.includes(movie.id);
      setAddedToWatchList(isAddedToWatchList);
      setWatchListHoverText(
        isAddedToWatchList
          ? "İzleme listesinden çıkart"
          : "İzleme listesine ekle"
      );
      const isAddedToWatchedMovies = userData.watchedMovies?.includes(movie.id);
      setAddedToWatchedMovies(isAddedToWatchedMovies);
      setWatchedMoviesHoverText(
        isAddedToWatchedMovies ? "İzlediklerimden çıkart" : "İzlediklerime ekle"
      );
    }

    if (movie) {
      fetch(
        `https://api.themoviedb.org/3/movie/${movie.id}/credits?api_key=${API_KEY}&language=tr-TR`
      )
        .then((response) => response.json())
        .then((data) => {
          const castList = data.cast.slice(0, 10).map((member) => ({
            id: member.id,
            name: member.name,
            character: member.character,
            profilePath: `https://image.tmdb.org/t/p/w500/${member.profile_path}`,
          }));
          setCast(castList);

          const directorData = data.crew.find(
            (crewMember) => crewMember.job === "Director"
          );
          if (directorData) {
            setDirector({
              id: directorData.id,
              name: directorData.name,
              profilePath: `https://image.tmdb.org/t/p/w500/${directorData.profile_path}`,
            });
          } else {
            setDirector({ name: "Bilinmiyor", profilePath: "" });
          }
        })
        .catch((error) => console.log(error));
    }
  }, [userData, movie]);

  const handleAddToWatched = async (e) => {
    e.preventDefault();

    const userRef = doc(db, "users", user.uid);
    if (addedToWatchedMovies) {
      await updateDoc(userRef, {
        watchedMovies: arrayRemove(movie.id),
        watchList: arrayRemove(movie.id),
      });
      setAddedToWatchedMovies(false);
      setWatchedMoviesHoverText("İzlediklerime ekle");

      // event kayıt etme
      await addDoc(collection(db, "events"), {
        userId: user.uid,
        id: movie.id,
        action: "removedFromWatched",
        timestamp: new Date(),
      });
    } else {
      await updateDoc(userRef, {
        watchedMovies: arrayUnion(movie.id),
      });
      setAddedToWatchedMovies(true);
      setWatchedMoviesHoverText("İzlediklerimden çıkart");
      if (addedToWatchList) {
        setAddedToWatchList(false);
        setWatchListHoverText("İzleme listesine ekle");

        // event kayıt etme
        await addDoc(collection(db, "events"), {
          userId: user.uid,
          id: movie.id,
          action: "movedFromWatchListToWatched",
          timestamp: new Date(),
        });
      } else {
        // event kayıt etme
        await addDoc(collection(db, "events"), {
          userId: user.uid,
          id: movie.id,
          action: "addedToWatched",
          timestamp: new Date(),
        });
      }
    }
  };

  const handleAddToWatchList = async (e) => {
    e.preventDefault();

    const userRef = doc(db, "users", user.uid);
    if (addedToWatchList) {
      await updateDoc(userRef, {
        watchList: arrayRemove(movie.id),
      });
      setAddedToWatchList(false);
      setWatchListHoverText("İzleme listesine ekle");

      // event kayıt etme
      await addDoc(collection(db, "events"), {
        userId: user.uid,
        id: movie.id,
        action: "removedFromWatchList",
        timestamp: new Date(),
      });
    } else {
      await updateDoc(userRef, {
        watchList: arrayUnion(movie.id),
        watchedMovies: arrayRemove(movie.id),
      });
      setAddedToWatchList(true);
      setWatchListHoverText("İzleme listesinden çıkart");
      if (addedToWatchedMovies) {
        setAddedToWatchedMovies(false);
        setWatchedMoviesHoverText("İzlediklerime ekle");

        // event kayıt etme
        await addDoc(collection(db, "events"), {
          userId: user.uid,
          id: movie.id,
          action: "movedFromWatchedToWatchList",
          timestamp: new Date(),
        });
      } else {
        // event kayıt etme
        await addDoc(collection(db, "events"), {
          userId: user.uid,
          id: movie.id,
          action: "addedToWatchList",
          timestamp: new Date(),
        });
      }
    }
  };

  return (
    movie && (
      <div className={styles.movie}>
        <img
          className={styles.poster}
          src={`https://image.tmdb.org/t/p/w500/${movie.poster_path}`}
          alt="Movie Poster"
        />
        <div className={styles.details}>
          <h1 className={styles.title}>{movie.title}</h1>
          <h2 className={styles.originalTitle}>{movie.original_title}</h2>
          <div className={styles.score}>
            <p>Puan: {Number(movie.vote_average).toFixed(1)}</p>
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.info}>
              <p className={styles.releaseDate}>
                Çıkış tarihi:{" "}
                {new Date(movie.release_date).toLocaleDateString("tr-TR")}
              </p>
              <p className={styles.runtime}>Süre: {movie.runtime} dk</p>
              <p className={styles.genres}>
                Tür: {movie.genres.map((genre) => genre.name).join(", ")}
              </p>
              <p className={styles.originalLanguage}>
                Dil: {convertLanguageCodesToNames(movie.original_language)}
              </p>
              {/* <p className={styles.productionCountries}>
                Ülkeler:{" "}
                {movie.production_countries
                  .map((country) => country.name)
                  .join(", ")}
              </p> */}
            </div>
            <div className={styles.buttonGroup}>
              <button
                className={`${styles.movieButton} ${
                  addedToWatchList ? styles.added : ""
                }`}
                onClick={handleAddToWatchList}
                onMouseEnter={() =>
                  setWatchListHoverText(
                    addedToWatchList
                      ? "İzleme listesinden çıkart"
                      : "İzleme listesine ekle"
                  )
                }
                onMouseLeave={() =>
                  setWatchListHoverText(
                    addedToWatchList
                      ? "İzleme listesinden çıkart"
                      : "İzleme listesine ekle"
                  )
                }
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>{watchListHoverText}</span>
              </button>

              <button className={styles.movieButton}>
                <FontAwesomeIcon icon={faStar} />
                <span> Puanla</span>
              </button>

              <button
                className={`${styles.movieButton} ${
                  addedToWatchedMovies ? styles.watched : ""
                }`}
                onClick={handleAddToWatched}
                onMouseEnter={() =>
                  setWatchedMoviesHoverText(
                    addedToWatchedMovies
                      ? "İzlediklerimden çıkart"
                      : "İzlediklerime ekle"
                  )
                }
                onMouseLeave={() =>
                  setWatchedMoviesHoverText(
                    addedToWatchedMovies
                      ? "İzlediklerimden çıkart"
                      : "İzlediklerime ekle"
                  )
                }
              >
                <FontAwesomeIcon icon={faCheck} />
                <span>{watchedMoviesHoverText}</span>
              </button>
            </div>
          </div>
          <h2>Yönetmen</h2>
          <div className={styles.director}>
            <Link
              to={{
                pathname: `/person/${director.id}`,
                state: { person: director },
              }}
            >
              <img src={director.profilePath} alt={director.name} />
              <h4>{director.name}</h4>
            </Link>
          </div>
          <h2>Özet</h2>
          <p className={styles.overview}>{movie.overview}</p>
          {trailerId && (
            <div className={styles.trailer}>
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${trailerId}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
          <div className={styles.castContainer}>
            <h4>Oyuncular:</h4>
            <div className={styles.cast}>
              {cast &&
                cast.map((actor, index) => (
                  <div key={index} className={styles.castMember}>
                    <Link
                      to={{
                        pathname: `/person/${actor.id}`,
                        state: { person: actor },
                      }}
                    >
                      <img src={actor.profilePath} alt={actor.name} />
                      <h4>
                        {actor.name} ({actor.character})
                      </h4>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.commentContainer}>
            {user ? (
              <MovieComments movie={movie} user={user} />
            ) : (
              <p>Bu bölümü görmek için giriş yapmalısınız.</p>
            )}
          </div>
        </div>
      </div>
    )
  );
}
